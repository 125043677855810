// Shimmer.js
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './Shimmer.css';

const Shimmer = () => {
  return (
    <div className="custom-shimmer">
      <SkeletonTheme color="#f3f3f3" highlightColor="#afc99b" duration={2}>
        {/* Customize the color and speed of the shimmer */}
        <Skeleton width={200} height={20} />
        <Skeleton count={8} />
      </SkeletonTheme>
    </div>
  );
};

export default Shimmer;
