import { createContext, useEffect, useMemo } from "react";

import axios from '../api/axios';

export const CommonDataContext = createContext({
    countryList: function () { }
});
export const CommonDataProvider = ({ children }) => {


    return (
        <CommonDataContext.Provider value={{

        }}>
            {children}
        </CommonDataContext.Provider>
    );
}
export default CommonDataProvider;