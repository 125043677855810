import { Alert, Snackbar } from '@mui/material'
import React, { useContext, useState } from 'react'
import ShoppingCartContext from '../../Context/ShoppingCartContext'
import MiniCartContainer from '../MiniCart/MiniCartContainer'
import HeaderContainer from './HeaderContainer'

const HeaderIndex = () => {
    let { isOpenMiniCart } = useContext(ShoppingCartContext);
    // console.log('isOpenMiniCart: ', isOpenMiniCart);

    return (
        <>
            <HeaderContainer></HeaderContainer>
            <MiniCartContainer isOpen={isOpenMiniCart} />

        </>
    )
}

export default HeaderIndex;