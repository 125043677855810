import { useContext } from 'react';

import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import RecentPosts from '../Recent_post';
import { ProductContext } from '../../Context/ProductContext';
import BestSellingIndex from '../BestSelling/BestSellingIndex';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import useScrollToTop from '../../hooks/useScrollToTop';
//import Accordion from '../homeAccordian';
import FaqAccordion from '../../pages/Faq/FaqAccordion'
import MailchimpForm from '../Mailchimp';

function HomePageContainer() {
    useScrollToTop();
    const settings = {
        dots: true,
        infinite: true,
        speed: 5,
        slidesToShow: 1,
        slidesToScroll: 1

    }
    let productList = useContext(ProductContext);
    // console.log('product List: ', productList);
    return (
        <div className="Apps">

            <div id="wrappers" className="clearfix">

                {/* <!-- Slider*/}

                <Slider {...settings}>

                    <div>
                        <Link to={'/shop'}>

                            <img alt='' width="100%" src="https://ayusage-public.s3.us-east-2.amazonaws.com/kaphasage_Kantkari.jpeg" />
                        </Link>
                    </div>
                    <div>
                        <Link to={'/shop'}>
                            <img alt='' width="100%" src="https://ayusage-public.s3.us-east-2.amazonaws.com/Hairsage_with-bhringraj.jpeg" />
                        </Link>
                    </div>

                </Slider>

                {/* #Slider End --> */}



                {/* <!-- Content */}
                <section id="content">
                    <div className="content-wrap pb-0">
                        <div className="container clearfix">

                            {/* <!-- ICON section
          ============================================= --> */}
                            <div className="row justify-content-center col-mb-50 mb-2">
                                <div className="col-sm-6 col-lg-3">
                                    <div className="feature-box fbox-sm fbox-plain">
                                        <div className="fbox-icon">
                                            <i className="icon-play-sign"></i>
                                        </div>
                                        <div className="fbox-content">
                                            <h3><Link style={{ color: '#333' }} to={'/consultation'} >Free Video consultation</Link></h3>
                                            <p>Get free consultation with top Ayurvedic doctors.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-3">
                                    <div className="feature-box fbox-sm fbox-plain" data-delay="200">
                                        <div className="fbox-icon">
                                            <a href="/shop"><i className="icon-star"></i></a>
                                        </div>
                                        <div className="fbox-content">
                                            <h3>Naturally Made</h3>
                                            <p>Our products are produced from 100% natural ingredients.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-3">
                                    <div className="feature-box fbox-sm fbox-plain" data-delay="400">
                                        <div className="fbox-icon">
                                            <i className="icon-line2-credit-card"></i>
                                        </div>
                                        <div className="fbox-content">
                                            <h3>Secure Payment Methods</h3>
                                            <p>We accept any payment method with secure payment gateway.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-sm-6 col-lg-3">
                                    <div className="feature-box fbox-sm fbox-plain" data-delay="800">
                                        <div className="fbox-icon">
                                            <i className="icon-line-search"></i>
                                        </div>
                                        <div className="fbox-content">
                                            <h3><Link style={{ color: '#333' }} to={'/bodytype'} >Know Your Dosha</Link></h3>
                                            <p>Check your body type with simple questions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sections my-4 pt-4">
                                <div className="container">
                                    <div className="row align-items-stretch">
                                        <div className="fancy-title title-border mt-4 title-center">
                                            <h4>Best Selling Products</h4>
                                        </div>
                                        <BestSellingIndex isBestSelling={true}></BestSellingIndex>
                                        <div className="fancy-title title-border mt-4 title-center">
                                            <a href="/shop" className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4">View All Products</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <!-- New Arrival Section
        ============================================= --> */}
                        <div className="section my-5">
                            <div className="container">
                                <div className="row align-items-stretch">
                                    <div className="col-md-5">
                                        <div className="row">
                                            <div className="col-md-12 center p-5">
                                                <div className="heading-block mb-1 border-bottom-0 mx-auto">
                                                    <div className="font-secondary text-black-50 mb-1">Featured</div>
                                                    <h3 className="nott ls0">Naturally Extracted</h3>
                                                    <p className="fw-normal mt-2 mb-3 text-muted"
                                                        style={{ "fontSize": "17px", "lineHeight": "1.4" }}>Ayusage products are based
                                                        on the principles of 5000 years old science of Ayurveda which works on
                                                        the root cause of the problem so as to have permanent and ever-lasting
                                                        health benefits.</p>
                                                    <a href="/shop" className="button bg-dark text-white button-dark button-small ms-0">Order Now</a>

                                                </div>
                                            </div>


                                            <div className="col-6 pb-3">
                                                <a href="/product/ashwagandha-with-brahmi"><img
                                                    src="https://ayusage-public.s3.us-east-2.amazonaws.com/product/ashwagandha-with-brahmi/ashwagandha.png" alt="Ashwagandha with Brahmi" /></a>
                                            </div>
                                            <div className="col-6 pb-3">
                                                <a href="/product/turmeric"><img
                                                    src="https://ayusage-public.s3.us-east-2.amazonaws.com/product/turmeric/Turmeric_Ayusage.png" alt="Turmeric" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <a href="/shop"> <img src="https://ayusage-public.s3.us-east-2.amazonaws.com/body_fitness.jpeg" alt="Body Fitness" title="Body Fitness" style={{ "width": "100%" }} /></a>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="clear"></div>

                        {/* <!-- Sign Up
        ============================================= --> */}
                        <div className="sections py-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row align-items-stretch align-items-center">
                                            <div className="col-md-7 min-vh-50"
                                                style={{ "background": "url('https://ayusage-public.s3.us-east-2.amazonaws.com/ayurveda-body-types.jpeg') center center", "backgroundRepeat": 'no-repeat', "backgroundSize": "contain" }}>

                                            </div>
                                            <div className="col-md-5 bg-white">
                                                <div className="card border-0 py-2">
                                                    <div className="card-body">
                                                        <h3 className="card-title mb-4 ls0">Know Your Body Type Free
                                                        </h3>
                                                        <h5>DISCOVER YOUR DOSHA (Ayurvedic Mind-Body Type)</h5>
                                                        <p>With our free 90-second quiz which is the ONLY Dosha quiz out there
                                                            that breaks down the results of your mind and your body, with
                                                            customized results for each!</p>
                                                        <Link to="/bodytype" className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4">KNOW YOUR DOSHA FREE Now</Link>
                                                        <Link to="/what-is-dosha" className="button button-rounded blue-btn ls0 fw-semibold ms-0 mb-2 nott px-4">WHAT IS DOSHA</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">

                            {/* <!-- Features
          ============================================= --> */}
                            <div className="row col-mb-50 mb-0 mt-5">
                                <div className="col-lg-7">
                                    <div className="row mt-3">
                                        <div className="col-sm-6">
                                            <div className="feature-box fbox-sm fbox-plain">
                                                <div className="fbox-icon">
                                                    <i className="icon-line2-star text-dark text-dark"></i>
                                                </div>
                                                <div className="fbox-content">
                                                    <h3 className="fw-normal">100% ORGANIC</h3>
                                                    <p>Ayusage products are 100% organic, gluten free that are produced from
                                                        natural ingredients at Land of Vedas.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-4 mt-sm-0">
                                            <div className="feature-box fbox-sm fbox-plain">
                                                <div className="fbox-icon">
                                                    <i className="icon-line2-present text-dark"></i>
                                                </div>
                                                <div className="fbox-content">
                                                    <h3 className="fw-normal">REFERRAL PROGRAM</h3>
                                                    <p>Refer your friend and get 25% discount when your friend place first order
                                                        with us.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-4">
                                            <div className="feature-box fbox-sm fbox-plain">
                                                <div className="fbox-icon">
                                                    <i className="icon-line2-user text-dark"></i>
                                                </div>
                                                <div className="fbox-content">
                                                    <h3 className="fw-normal">EXPERT ADVICE</h3>
                                                    <p>Ayusage provide 30 minutes free video consultation with our Ayurveda
                                                        Expert.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-4">
                                            <div className="feature-box fbox-sm fbox-plain">
                                                <div className="fbox-icon">
                                                    <i className="icon-line2-credit-card text-dark"></i>
                                                </div>
                                                <div className="fbox-content">
                                                    <h3 className="fw-normal">SECURE PAYMENT OPTIONS</h3>
                                                    <p>We accept Visa, MasterCard, Google Pay and American Express secure
                                                        payment options.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 Mui-accordion clearfix">
                                    <FaqAccordion title={"Why choose us?"} content={"Ayusage’s product are designed by specialist ayurveda team of doctors hence when it comes to results our products are best in class. Ayusage provide 100% Natural Authentic supplement store across the world that are clinically proven on millions of patients at practice from the land of veda.Ayusage’s products are based on the principle of ayurveda which works only roots of problem so to have permanent and ever lasting benefits."}></FaqAccordion>
                                    <FaqAccordion title={"Our Mission"} content={"The foundation of Ayusage’s mission is based upon our vision to help people all across the world to achieve absolute balance of physical, mental and spritual health through holistic ways we fulfill our mission by producing highest quality of Ayuvedic products and supplements. Ayusage products that are made from 100% natural and authentic ayurvedic ingredients that directly delivered from the land of veda. Ayusage’s mission is to keep on formulating products based upon the principles of ayurveda which works on the root cause of the problem to have permanent and everlasting benefits."}></FaqAccordion>

                                    <FaqAccordion title={"Our Return Policy"} content={"Please refer to our return policy and Contact Us for more detail."}></FaqAccordion>
                                </div>

                            </div>

                            <div className="clear"></div>
                        </div>

                        <div className="clear"></div>

                        {/* <!-- Recent Posts --> */}

                        <RecentPosts></RecentPosts>


                        {/* <!-- Last Section --> */}
                        <div className="section mb-0">
                            <div className="container clearfix">

                                <div className="heading-block center">
                                    <h3>Subscribe to our <span>Newsletter</span></h3>
                                    <p>Subscribe to Our Newsletter to get Important News, Amazing Offers & Inside Scoops:
                                    </p>
                                </div>

                                <div className="subscribe-widget">
                                    <div className="widget-subscribe-form-result"></div>

                                    <div className="input-group input-group-lg mx-auto" style={{ "maxWidth": "600px" }}>

                                        {/* <div className="input-group-text"><i className="icon-email2"></i></div> */}
                                        <MailchimpForm></MailchimpForm>
                                    </div>


                                </div>

                            </div>
                        </div>


                    </div>
                </section>
                {/* <!-- #content end --> */}


            </div >
            {/* <!-- #wrapper end --> */}


        </div >
    );
}

export default HomePageContainer;