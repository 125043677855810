import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';

const BlogPage = () => {

    const [blogList, setData] = useState([]);


    const getBlog = useCallback(async () => {

        const response = await axios.get('/api/v1/blog');
        setData(response?.data?.data);

    }, []);
    console.log(blogList);

    useEffect(() => {
        getBlog();
    }, [getBlog]);

    return <>
        <Helmet>
            <title>Blog | Natural herbal Ayurvedic Supplement - Land of Vedas</title>
            <meta name="description" content="Checkout our latest Ayurvedic and Health related blogs." />
            <link rel="canonical" href="https://www.ayusage.com/blog" />
            <meta property="og:title" content="Blog | Natural herbal Ayurvedic Supplement - Land of Vedas" />
            <meta property="og:description" content="Checkout our latest Ayurvedic and Health related blogs." />
        </Helmet>
        <section id="page-title" className='' style={{ "background": "url('./blog.jpeg')" }}>
            <div className="container">
                <h1>Blogs</h1>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">

                    <div id="posts">

                        {/* Post div start */}
                        {/* {JSON.stringify(blogList)} */}
                        {blogList && blogList.map((item, index) => (<div key={index} id={'postid-' + item.id} className="row">
                            <div className="col-md-3">
                                <Link to={'/blog/' + item.slug} ><img src={`/blogImg/${item.img}`} alt="Three Body Types" height="200px" className='' /></Link>
                            </div>
                            <div className="col-md-9">
                                <div className="entry-title title-sm">
                                    <h2><Link to={'/blog/' + item.slug} >{item.title}</Link></h2>
                                    <i className="icon-calendar3"></i> {moment(item?.created_on).format('MMMM DD, YYYY')} | <i className="icon-user"></i> {item?.posted_by} | <i className="icon-folder-open"></i> {item?.cat}
                                </div>
                                <div className="entry-content">
                                    <p>{item.short_description}</p>
                                    <Link to={'/blog/' + item.slug} className="more-link">Read More</Link><i className="icon-line-arrow-right color"></i>
                                </div>
                            </div>

                            <div className="line"></div>
                        </div>
                        )

                        )}

                        {/* Post div ends */}

                    </div>
                </div>
            </div>
        </section>

    </>
}
export default BlogPage;