import { BrowserRouter, Routes, Route, NavLink, Link } from 'react-router-dom';

function MainNavigation() {
    return (
        <header className='' >
            <nav className="primary-menu with-arrows me-lg-auto">

                <ul className="menu-container">
                    <li className="menu-item">
                        <div className="menu-link"><a href="/">Home</a></div>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link"><a href="/about">About</a></div>
                    </li>
                    <li className="menu-item mega-menu mega-menu-small">
                        <div className="menu-link"><a href="/shop">Shop</a></div>

                        <div className="mega-menu-content mega-menu-style-2">
                            <div className="container">
                                <div className="row">
                                    <ul className="sub-menu-container mega-menu-column col-lg-6">
                                        <li className="menu-item mega-menu-title">
                                            <a className="menu-link" href="#">
                                                <div>Herbs</div>
                                            </a>
                                            <ul className="sub-menu-container">
                                                <li className="menu-item">
                                                    <a className="menu-link" href={'/product/ashwagandha-with-brahmi'}>
                                                        Ashwagandha
                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="menu-link" href={'/product/hairsage'}>
                                                        Hairsage
                                                    </a></li>

                                                <li className="menu-item">
                                                    <a className="menu-link" href={'/product/kaphasage'}>
                                                        Kaphasage
                                                    </a>
                                                </li>
                                                <li className="menu-item"><a className="menu-link" href={'/product/moringa'}>
                                                    <div>Moringa</div>
                                                </a></li>

                                            </ul>
                                        </li>
                                    </ul>
                                    <ul className="sub-menu-container mega-menu-column col-lg-6">
                                        <li className="menu-item mega-menu-title"><a className="menu-link" href="#">
                                            <div>Immunity</div>
                                        </a>
                                            <ul className="sub-menu-container">
                                                <li className="menu-item">
                                                    <a className="menu-link" href={'/product/imunisage'}>
                                                        Imunisage
                                                    </a>
                                                </li>

                                                <li className="menu-item"><a className="menu-link" href={'/product/mucuna'}>
                                                    <div>Mucuna</div>
                                                </a></li>
                                                <li className="menu-item"><a className="menu-link" href={'/product/tribulus'}>
                                                    <div>Tribulus</div>
                                                </a></li>
                                                <li className="menu-item"><a className="menu-link" href={'/product/turmeric'}>
                                                    <div>Turmeric</div>
                                                </a></li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link"><a href="/contact">Contact</a></div>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link"><a href="/blog">Blog</a></div>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link"><a href="/bodytype">Know Your Body Type</a></div>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link"><a href="/consultation">Book Free Consultation</a></div>
                    </li>
                </ul>

            </nav>

        </header>
    );
}

export default MainNavigation;