import React from 'react';

const ProgressBar = ({ current, total }) => {
    let getPercentage = ((current / total) * 100).toFixed(0);
    const progressPercent = getPercentage >= 100 ? 100 : getPercentage;

    const progressBarStyles = {
        width: `${progressPercent}%`,
        maxWidth: '100%',
        height: '10px',
        backgroundColor: '#7CC245',
        transition: 'width 0.3s ease',
        borderRadius: '10px',
        background: '#f0f0f0' /* Background color */
    };

    const prgBar = {
        width: '90%', /* Set the width of the progress bar */
        maxWidth: '100%', /* Ensure it doesn't overflow the page */
        margin: '20px auto', /* Center horizontally with some margin */
        height: '20px', /* Set the height of the progress bar */
        borderRadius: '10px',
        background: '#ffffff', /* Background color */
        position: 'relative'

    }
    const progressFill = {
        height: '100%', /* Fills the progress bar vertically */
        width: `${progressPercent}%`, /* Starts with 0 width */
        background: '#00afef', /* Active color */
        borderRadius: '10px',
        transition: 'width 0.5s ease-in-out' /* Smooth transition */
    }

    const progressText = {
        position: 'absolute',
        top: '50%',
        right: '10px', /* Adjust the distance from the end */
        transform: 'translateY(-50%)',
        color: '#333', /* Text color */
        fontWeight: 'bold'
    }

    return (
        <div className='dosha-bar'>
            {/* <div style={progressBarStyles}></div> */}
            <div className="progress-bar" style={prgBar}>
                <div className="progress-fill" style={progressFill}></div>
                <div className="progress-text" style={progressText}> {progressPercent >= 100 ? (<span style={{ color: 'white' }}>Your are almost done.</span>) : progressPercent + '%'}</div>
            </div>
        </div>
    );
};

export default ProgressBar;
