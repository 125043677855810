import { Outlet } from "react-router-dom"
// import MainNavigation from "../component/MainNavigation";
import FooterPage from "./Footer"
import HeaderPage from "../component/Header/HeaderIndex"

import useAuth from "../hooks/useAuth";

const UserAccountRootLayout = () => {
    const { auth } = useAuth();
    console.log('Auth in user account layout: ', auth);
    return <>
        <div className="container">
            <HeaderPage />
            <Outlet />
            <FooterPage />
        </div>
    </>
}

export default UserAccountRootLayout;