import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {
    return <>
        <Helmet>
            <title>Privacy Policy | Ayusage Private Limited - Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Explore our Privacy Policy to understand how we collect, use, and safeguard your personal information." />
        </Helmet>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-sm-12">

                            <h2>Privacy Policy</h2>
                            <p>Thank you for visiting at ayusage.com!</p>
                            <p>At Ayusage, accessible from <Link to="/">www.ayusage.com</Link>, one of our main priorities is to protect privacy of our visitors.
                                We are committed to respecting the privacy rights of all individuals. We store and keep secure any of your required data only. This Privacy Policy document contains types of information that is collected and recorded by Ayusage and how we use it.
                                If you have additional questions or require more information about our Privacy Policy, do not hesitate to <a href='/contact'>contact us</a>.
                                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Ayusage. This policy is not applicable to any information collected offline or via channels other than this website.
                            </p>
                            <strong>IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, DO NOT USE ANY OF THE SITES OR SERVICES OR GIVE US ANY OF YOUR DATA. YOUR CONTINUED USE OF ANY OF THE SITES AND/OR SERVICES MEANS THAT YOU AGREE TO THIS PRIVACY POLICY.</strong>

                            <h3>Consent</h3>
                            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                            <h3>Information we collect</h3>
                            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

                                If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

                                When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                            </p>

                            <h3>How we use your information</h3>
                            <p>How we use your information</p>
                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>

                            <h3>Log Files</h3>
                            <p>Ayusage follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>

                            <h3>Advertising Partners Privacy Policies</h3>
                            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Ayusage.
                                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Ayusage, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                                <br></br>
                                <strong>Note</strong> that Ayusage has no access to or control over these cookies that are used by third-party advertisers.</p>

                            <h3>Third Party Privacy Policies</h3>
                            <p>Ayusage’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

                                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.</p>

                            <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
                            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                            <ul>
                                <li>Request that a business that collects a consumer personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                                <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                                <li>Request that a business that sells a consumer personal data, not sell the consumer personal data.</li>
                                <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
                            </ul>

                            <h3>GDPR Data Protection Rights</h3>
                            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                            <ul>
                                <li>The right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
                                <li>The right to rectification - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                                </li>
                                <li>The right to erasure - You have the right to request that we erase your personal data, under certain conditions.
                                </li>
                                <li>The right to restrict processing - You have the right to request that we restrict the processing of your personal data, under certain conditions.
                                </li>
                                <li>The right to object to processing - You have the right to object to our processing of your personal data, under certain conditions.
                                </li>
                                <li>The right to data portability - You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                                </li>
                            </ul>

                            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please <Link to="/contact">contact us</Link>.

                            </p>



                            <b>**Data Retention**</b>

                            <p>We retain your personal data for as long as it is necessary to fulfill the purposes mentioned in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

                            <b>**Data Security**</b>

                            <p>We take data security seriously and have implemented security measures, including encryption, access controls, and regular security audits, to protect your personal information from unauthorized access or disclosure.</p>

                            <b>**Children's Privacy**</b>

                            <p>Our website is not intended for children under the age of 15years. We do not knowingly collect or store personal information from individuals under this age. If you believe that we have inadvertently collected data from a minor, please contact us immediately, and we will take steps to remove the information.</p>

                            <b>**Data Transfers**</b>

                            <p>In some cases, we may transfer your data internationally or to third parties for the purposes outlined in this Privacy Policy. We have safeguards in place to ensure the protection of your data during such transfers.</p>

                            <b>**Data Breach Notification**</b>

                            <p>In the event of a data breach that poses a risk to your privacy, we will notify you promptly, outlining the nature of the breach and the steps we are taking to mitigate potential harm.</p>

                            <b>**Contact Information**</b>

                            <p>If you have any questions or concerns regarding your privacy or data-related inquiries, please contact us at info@ayusage.com or ayusgae5@gmail.com.</p>

                            <b>**Cookie Consent**</b>

                            <p>Our website uses cookies to enhance your user experience. By using our website, you consent to the use of cookies as explained in our Cookie Policy. You may manage your cookie preferences through your browser settings.</p>

                            <b>**Updates to Privacy Policy**</b>

                            <p>This Privacy Policy may be updated from time to time to reflect changes in data handling practices or regulations. You can find the most current version on our website. Continued use of the website after policy changes implies acceptance of the updated terms.</p>

                            <b>**Legal Basis for Data Processing**</b>

                            <p>Where applicable, we process your data based on legal grounds such as consent, legitimate interests, or contract performance, in accordance with relevant data protection laws.</p>

                            <b>**User Rights**</b>

                            <p>You have the right to access, correct, delete, or restrict the processing of your personal data, under certain conditions. You may also request data portability. To exercise any of these rights, please contact us.</p>


                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
}
export default PrivacyPolicyPage;