import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";

import api from '../common/config';
import { Helmet } from 'react-helmet';
import axios from '../api/axios';

// !!! IMPORTANT BEFORE LIVE: add google captcha. validate IP address and browser type.

function ContactPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [queryType, setQueryType] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [alertType, setAlertType] = useState('success');

    const [isShowAlertMessage, setIsShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Thank you! Detail has been submitted successfully — Support Team will contact you soon!');

    const [recaptchaScore, setRecaptchaScore] = useState(null);

    // const handleCaptchaChange = (value) => {
    //     // This callback is triggered when the user solves the captcha
    //     setCaptchaVerified(true);
    // };
    useEffect(() => {
        // Load reCAPTCHA script and get the score
        const loadReCaptchaScript = async () => {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=6LdFZz0pAAAAAHcYpQQCZuYMWHcsbyZNxEikcSuQ"; // Replace with your reCAPTCHA v3 site key
            script.async = true;
            console.log('6LdFZz0pAAAAAHcYpQQCZuYMWHcsbyZNxEikcSuQ:');
            script.onload = () => {
                // After the script is loaded, request a reCAPTCHA token
                window.grecaptcha.ready(async () => {
                    const recaptchaResponse = await window.grecaptcha.execute(
                        "6LdFZz0pAAAAAHcYpQQCZuYMWHcsbyZNxEikcSuQ", // Replace with your reCAPTCHA v3 site key
                        { action: "submit" } // Replace with an appropriate action
                    );
                    setRecaptchaScore(recaptchaResponse);
                });
            };

            document.body.appendChild(script);
        };

        loadReCaptchaScript();
    }, []);

    const handleName = (event) => {
        validateForm();
        // Perform validation to block script tags or other unwanted patterns
        const sanitizedValue = event.target.value.replace(/<script.*?>|<\/script>/gi, '');
        setName(sanitizedValue);

    };
    const handleEmail = (event) => {
        validateForm();
        const sanitizedValue = event.target.value.replace(/<script.*?>|<\/script>/gi, '');
        setEmail(sanitizedValue);

    };
    const handlePhone = (event) => {
        // validateForm();
        setPhone(event.target.value);
    };
    const handleQueryType = (event) => {
        // validateForm();
        setQueryType(event.target.value);
    };
    const handleMessage = (event) => {
        //validateForm();
        const sanitizedValue = event.target.value.replace(/<script.*?>|<\/script>/gi, '');
        setMessage(sanitizedValue);
    };


    const validateForm = () => {
        const newErrors = {};
        // Validate name
        if (name.trim() === '') {
            newErrors.name = 'Name is required';
        }

        // Validate email
        if (email.trim() === '') {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!/^\d{10}$/.test(phone)) {
            newErrors.phone = 'Invalid Phone Number';
        }
        console.log('Query Type: ', queryType);

        if (queryType.trim() === '') {
            newErrors.queryType = 'Select Query type';
        }
        if (message.trim() === '') {
            newErrors.message = 'Additonal Information is required';
        }
        setErrors(newErrors);

        // Return true if the form is valid (no errors)
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Here you can add your logic for submitting the form data to your server or API
        console.log({ name, email, phone, queryType, message });
        let formData = {
            name,
            email,
            phone,
            query_type: queryType,
            message,
            subject: 'Thanks for contact Ayusage Pvt. Ltd.'
        }
        if (validateForm()) {
            try {
                const response = await axios.post('/api/v1/form/contactus', formData);
                setName('');
                setEmail('');
                setPhone('');
                setQueryType('');
                setMessage('');
                if (response) {
                    setIsShowAlertMessage(true);
                    setAlertType('success');
                    console.log(response.data); // Handle the response data..

                }
            } catch (error) {
                setIsShowAlertMessage(false);
                setAlertType('error');
                setAlertMessage(`Somthing Went Wrong: ${error}`);
                console.error('Error:', error);
            }
        }


    };
    const handleCloseMessage = () => {
        setIsShowAlertMessage(false);
        console.log('handleCloseMessage: ');
    }
    return <>
        <Helmet>
            <title>Contact Us | Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Contact us and get help with your all queries related to health and body types. Contact our team!" />
            <meta property="og:title" content="Contact Us" />
            <meta property="og:description" content="Contact us and get help with your all queries related to health and body types. Contact our team!" />
        </Helmet>
        <section id="page-title" className='' style={{ "background": "url('./ayurveda.jpeg')" }}>
            <div className="container">
                <h1>Contact Us</h1>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="sections row">
                        <div className="col-sm-6 col-lg-6">
                            {isShowAlertMessage && <Alert severity={alertType} onClose={handleCloseMessage}>{alertMessage}</Alert>}
                            <form className="contacts shadow" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>Name<small>*</small></label>
                                        <input type="text" id="template-contactform-name" name="template-contactform-name" className="sm-form-control"
                                            value={name}
                                            onChange={handleName}
                                        />
                                        <span className='error'>{errors.name && <span>{errors.name}</span>}</span>

                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label>Email<small>*</small></label>
                                        <input type="email" id="template-contactform-name" name="template-contactform-name" className="sm-form-control" value={email} onChange={handleEmail} />
                                        <span className='error'>{errors.email && <span>{errors.email}</span>}</span>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Phone </label>
                                        <input type="text" id="template-contactform-name" name="template-contactform-name" className="sm-form-control" value={phone} onChange={handlePhone} />
                                        {/* <span className='error'>{errors.phone && <span>{errors.phone}</span>}</span> */}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Query Type</label>
                                        <select id="template-contactform-service" name="template-contactform-service" className="sm-form-control" value={queryType} onChange={handleQueryType} >
                                            <option value="">Select One</option>
                                            <option value="general">General Inquiry</option>
                                            <option value="return">Return Request</option>
                                            <option value="order-status">Order Status</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {/* <span className='error'>{errors.queryType && <span>{errors.queryType}</span>}</span> */}
                                    </div>

                                    <div className="col-12 form-group">
                                        <label>Message </label>
                                        <textarea className="sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30" value={message} onChange={handleMessage} />
                                        {/* <span className='error'>{errors.message && <span>{errors.message}</span>}</span> */}
                                    </div>
                                    <div className="captcha">
                                        {/* <ReCAPTCHA sitekey="6LdFZz0pAAAAAHcYpQQCZuYMWHcsbyZNxEikcSuQ" onChange={setRecaptchaScore} /> */}
                                        {/* <ReCAPTCHA sitekey="6LdFZz0pAAAAAHcYpQQCZuYMWHcsbyZNxEikcSuQ" /> */}

                                    </div>
                                    <div className="col-12 form-group">
                                        <button name="submit" type="submit" id="submit-button" tabIndex={"5"} value="Submit" className="button button-3d m-0">Submit</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <h2>How Can We Help?</h2>
                            <p>Please select a topic below to your inquiry. If you don't find anything relevant, fill out our contact form.</p>
                            <h4 style={{ color: "#7CC245" }}><Link to="/consultation">Book Free Consultation</Link></h4>
                            <p>Book free video consultation with top Ayurvedic specialists.</p>
                            <hr></hr>
                            <h4 style={{ color: "#7CC245" }}><Link to="/bodytype">Know Your Body Type</Link></h4>
                            <p>Know your body type by referring Dosha quiz and get online assessment.</p>
                            <hr></hr>
                            <h4 style={{ color: "#7CC245" }}><Link to="/faq">Review Frequently Asked Questions</Link></h4>
                            <p>Review Frequently Asked Questions</p>
                            <hr></hr>
                        </div>
                    </div>

                </div>
            </div>
        </section >
    </>
}
export default ContactPage;