

import React, { createContext, useEffect, useState, useCallback } from 'react';
import axios from '../api/axios';
export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {


    useEffect(() => {

    }, []);
    const saveBillingDetailAsDraft = useCallback(async (billingData) => {
        console.log('billingData in provider:', billingData);
        let response = await axios.post('/api/v1/order/saveBillingAsDraft', billingData);
        console.log('Save Billing Detail As Draft', response);
        return response?.data?.data?.last_inserted_billing_id || '';

    }, []);
    const saveShippingDetailAsDraft = useCallback(async (shippingData) => {
        console.log('billingData in provider:', shippingData);
        let response = await axios.post('/api/v1/order/saveShippingAsDraft', shippingData);
        console.log('Save shipping Detail As Draft', response);

    }, []);

    return (
        <OrderContext.Provider value={{
            saveBillingDetailAsDraft,
            saveShippingDetailAsDraft
        }}>
            {children}
        </OrderContext.Provider>
    );

}
