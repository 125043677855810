import React, { createContext, useEffect, useState, useCallback } from 'react';
import axios from '../api/axios';
export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {

    const [productList, setProductList] = useState([]);

    useEffect(() => {
        fetchProductList();
    }, []);
    const fetchProductList = useCallback(async () => {
        let response = await axios.get(`/api/v1/product/list?is_best_selling=${false}`);
        if (response && response?.data?.data) {
            setProductList(response?.data?.data);
        }
    }, []);

    return (
        <ProductContext.Provider value={{ productList: productList }}>
            {children}
        </ProductContext.Provider>
    );

}
