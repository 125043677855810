import React from 'react'
import BestSellingIndex from '../BestSelling/BestSellingIndex'

const RecommendationProducts = () => {
    return (
        <div>
            <h3 className='mb-5'>Most Selling Supplements:</h3>
            <BestSellingIndex isBestSelling={true}></BestSellingIndex>

        </div>
    )
}

export default RecommendationProducts