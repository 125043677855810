
import React, { createContext, useState, useEffect } from 'react';

const ShoppingCartContext = createContext({
    getItemQuantity: function (id) { },
    increaseCartQuantity: function (id) { },
    decreaseCartQuantity: function (id) { },
    removeFromCart: function (id) { },
    openCart: function () { },
    closeCart: function () { },
    cartItems: [],
    cartQuantity: 0,
    isOpenMiniCart: false,
    updateCartItems: function () { },
    removeAllItemFromCart: function () { },
    latestProductAdded: {}
});

export const ShoppingCartProvider = ({ children }) => {

    // const [cartItems, setCartItems] = useState([]);

    const [isOpenMiniCart, setIsOpenMiniCart] = useState(false)
    const [cartQuantity, setCartQuantity] = useState(0);
    const [latestProductAdded, setLatestProductAdded] = useState({});


    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        let savedCartItems = localStorage.getItem('cartItems');

        try {
            savedCartItems = savedCartItems ? JSON.parse(savedCartItems) : [];

        } catch (error) {
            savedCartItems = [];
        }

        setCartItems(savedCartItems);
        const currentCartQuantity = savedCartItems.reduce(
            (quantity, item) => {
                return item.quantity + quantity
            }, 0);
        setCartQuantity(currentCartQuantity);
    }, [])

    function getItemQuantity(id) {
        return cartItems.find(item => item.id === id)?.quantity || 0;
    }

    const openCart = () => setIsOpenMiniCart(true)
    const closeCart = () => {
        setLatestProductAdded({});
        return setIsOpenMiniCart(false)
    }

    function updateCartItems(items) {
        setCartItems(items);
        setCartQuantity(items.length);
    }
    function increaseCartQuantity(id) {
        // console.log('check id increase: ', id);

        const currentCartQuantity = cartItems.reduce(
            (quantity, item) => {
                return item.quantity + quantity
            }, 0);

        // console.log('currentCartQuantity: ', currentCartQuantity);

        setCartQuantity(currentCartQuantity + 1);

        setCartItems(currItems => {
            let updatedCartItems = [];
            const existingItem = currItems.find(item => item.id === id);
            if (existingItem) {
                updatedCartItems = currItems.map(item => {
                    if (item.id === id) {
                        return { ...item, quantity: item.quantity + 1 };
                    }
                    return item;
                });
            } else {
                updatedCartItems = [...currItems, { id, quantity: 1 }];
            }
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
            return updatedCartItems;
        });
    }

    function decreaseCartQuantity(id) {
        setCartItems(currItems => {
            let updatedCartItems = [];
            const existingItem = currItems.find(item => item.id == id);
            if (existingItem) {
                if (existingItem.quantity === 1) {
                    updatedCartItems = currItems.filter(item => item.id != id);
                } else {
                    updatedCartItems = currItems.map(item => {
                        if (item.id === id) {
                            return { ...item, quantity: item.quantity - 1 };
                        }
                        return item;
                    });
                }
            }
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
            let totalQuantity = 0;
            for (const item of updatedCartItems) {
                totalQuantity += item.quantity;
            }
            setCartQuantity(totalQuantity);
            return updatedCartItems;
        });
    }

    function removeFromCart(id) {
        setCartItems(cartItems => {
            let updateCartAfterRemove = cartItems.filter(item => item.id != id);
            localStorage.setItem('cartItems', JSON.stringify(updateCartAfterRemove));
            let remainingCartItems = localStorage.getItem('cartItems');

            remainingCartItems = remainingCartItems ? JSON.parse(remainingCartItems) : [];
            // console.log('remainingCartItems: ', remainingCartItems);

            let totalQuantityAfterRemoved = 0;
            for (const item of remainingCartItems) {
                totalQuantityAfterRemoved += item.quantity;
            }
            setCartQuantity(totalQuantityAfterRemoved);


            return updateCartAfterRemove;
        });
    }

    // Load cart items from local storage on component mount
    useEffect(() => {
        const savedCartItems = localStorage.getItem('cartItems');
        if (savedCartItems && savedCartItems !== 'undefined') {
            setCartItems(JSON.parse(savedCartItems));
        }
    }, []);

    // Clear all cart from local storage and state...
    const removeAllItemFromCart = () => {
        localStorage.removeItem('cartItems');
        setCartItems([]);
        setCartQuantity(0)
    }

    return (
        <ShoppingCartContext.Provider value={{
            getItemQuantity,
            increaseCartQuantity,
            decreaseCartQuantity,
            removeFromCart,
            openCart,
            closeCart,
            cartItems,
            cartQuantity,
            isOpenMiniCart,
            updateCartItems,
            removeAllItemFromCart,
            setLatestProductAdded,
            latestProductAdded
        }}>
            {children}
        </ShoppingCartContext.Provider>
    );
};

export default ShoppingCartContext;

