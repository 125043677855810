import { Link } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../api/axios';
import useScrollToTop from '../hooks/useScrollToTop';


const SubmitKnowYourBodyType = () => {
    useScrollToTop();
    localStorage.clear('cartItems');
    const location = useLocation();
    const { state } = location;

    const [bodyTypeText, setBodyTypeText] = useState(null);

    useEffect(() => {
        if (state && state.new_data) {
            setBodyTypeText(state.bodyTypeText);
        }

        // if (state && state.new_data) {
        //     let bodyTypeText;
        //     const receivedData = state.new_data;
        //     console.log('Received data:', JSON.stringify(receivedData[0]));
        //     // Extract answers from data
        //     const answers = receivedData[0].dataToSend.map((item) => item.answer);

        //     // Count occurrences of each answer
        //     const answerCounts = answers.reduce((acc, answer) => {
        //         acc[answer] = (acc[answer] || 0) + 1;
        //         return acc;
        //     }, {});

        //     // Convert answerCounts to an array of objects
        //     const answerCountsArray = Object.entries(answerCounts).map(([answer, count]) => ({
        //         answer,
        //         count,
        //     }));

        //     // Sort answerCountsArray by count in descending order
        //     answerCountsArray.sort((a, b) => b.count - a.count);

        //     // Extract most, second most, and third most common answers
        //     const mostCommon = answerCountsArray[0];
        //     const secondMostCommon = answerCountsArray[1];
        //     const thirdMostCommon = answerCountsArray[2];
        //     console.log('mostCommon: ', mostCommon, 'secondMostCommon: ', secondMostCommon, 'thirdMostCommon: ', thirdMostCommon)
        //     // return { mostCommon, secondMostCommon, thirdMostCommon };
        //     const dominantType = mostCommon.answer;
        //     const secondDominantType = secondMostCommon.answer;
        //     console.log('Dominant Type: ', dominantType);
        //     console.log('Second Dominant Type: ', secondDominantType);
        //     if ((dominantType === 'A' && secondDominantType === 'B')) {
        //         bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Kapha dominant Pitta type.`;
        //     }
        //     else if (dominantType === 'B' && secondDominantType === 'A') {
        //         bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Pitta dominant Kapha type.`;
        //     }
        //     else if ((dominantType === 'A' && secondDominantType === 'C')) {
        //         bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Kapha dominant Vata type.`;
        //     }
        //     else if (dominantType === 'C' && secondDominantType === 'A') {
        //         bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Vata dominant Kapha type.`;
        //     }
        //     else if ((dominantType === 'B' && secondDominantType === 'C')) {
        //         bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Pitta dominant Vata type.`;
        //     }
        //     else if (dominantType === 'C' && secondDominantType === 'B') {
        //         bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Vata dominant Pitta type.`;
        //     }
        //     else {
        //         console.log('Last options..');
        //         bodyTypeText('Somthing went wrong, Please contact support team.');
        //         // setBodyTypeText('Somthing went wrong, Please contact support team.');
        //     }
        //     if (bodyTypeText && bodyTypeText !== null && (dominantType === 'A' || dominantType === '' || dominantType === 'C')) {
        //         sendEmail(bodyTypeText);
        //         setBodyTypeText(bodyTypeText);
        //     }

        // }
    }, [state]);

    // const sendEmail = (async () => {
    //     let data = {
    //         body_type_text: bodyTypeText,
    //         body_type_description: 'Know more about your body, mind and holistic ways to balance your doshas  for healthy body and mind.'
    //     }
    //     const response = await axios.post('/api/v1/sendEmail', data);
    //     console.log('Response Send Email :=> ', response);
    // })

    return (
        <div className='text-center'>
            <img width="250" height="250" src='/thank-you-img.png' alt='thank-you' ></img>
            <h3>{bodyTypeText}</h3>
            <p>Shortly, You will be get more details by email.</p>
            <p>Click<a href='/shop' alt={'shop again'} > here to shop</a></p>

        </div>
    )
}

export default SubmitKnowYourBodyType