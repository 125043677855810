import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
// import Axios from 'axios';
import products from "../../data/Products";
import CartPage from '../../pages/Cart';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { Alert, Icon } from '@mui/material';

import './Index.css';
import ShoppingCartContext from '../../Context/ShoppingCartContext';

const BestSellingContainer = ({ type }) => {
    let navigate = useNavigate();
    const { openCart, increaseCartQuantity, setLatestProductAdded } = useContext(ShoppingCartContext)

    const [productList, setProductList] = useState([]);
    // const [addedProductName, setAddedProductName] = useState([]);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state to track the success message


    const fetchBestSellingProduct = useCallback(async () => {
        let url;
        if (type === 'all') {
            url = '/api/v1/product/list';
        } else {
            url = '/api/v1/product/best_selling_product';
        }
        const response = await axios.get(url);

        if (response && response.data && response.data.data) {
            setProductList(response?.data?.data);
        }
    }, []);


    const handleAddToCart = async (product, product_id, product_name) => {
        try {
            await increaseCartQuantity(product_id);
            await openCart();
            setShowSuccessMessage(true);
            setLatestProductAdded(product);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            // Handle the error here if needed
            console.error("Error adding item to cart:", error);
        }
    };

    useEffect(() => {
        fetchBestSellingProduct();
    }, [fetchBestSellingProduct]);

    const redirectToSingleProduct = (slug) => {
        navigate(`/product/${slug}`)
    }
    return (
        <>
            {/* <!-- Shop Products 
      ============================================= --> */}
            {/* {showSuccessMessage && <Alert onClose={() => { }}>{addedProductName} added to cart successfully! <Link to={'/cart'} >View Cart</Link></Alert>} */}
            <div className="row">
                {productList && productList.map((product, key) => (
                    <div className="col-sm-3 col-lg-3" key={product.id}>
                        <div className="product">
                            <div className="product-image shadow">

                                <a href={`/product/${product.slug}`}>
                                    <img src={`https://ayusage-public.s3.us-east-2.amazonaws.com/product/${product.slug}/${product.main_image}`} alt={product.name} />
                                </a>
                                <a href={`/product/${product.slug}`}>
                                    <img className='item-img' src={`https://ayusage-public.s3.us-east-2.amazonaws.com/product/${product.slug}/${product.thumbnail_1}`}
                                        alt={product.name} />
                                </a>
                                <div className="bg-overlay">
                                    <div onClick={() => redirectToSingleProduct(product.slug)} className="bg-overlay-content align-items-end" data-hover-animate="fadeIn"
                                        data-hover-speed="400">
                                        <a className="btn btn-dark"
                                            href={`/product/${product.slug}`}>View Detail</a>
                                    </div>
                                    <div className="bg-overlay-bg bg-transparent"></div>
                                </div>
                            </div>
                            {product?.sale_price < product?.price && (<div className="sale-flash badge bg-danger p-2">Sale!</div>)}
                            <div className="product-desc">
                                <div className="left-description">
                                    <div className="product-title mb-1">
                                        <h3> <Link to={`/product/${product.slug}`}>{product?.name}</Link></h3>
                                    </div>
                                    <div className="product-price font-primary">
                                        {product?.sale_price < product?.price && (<del className="me-1">${product?.price.toFixed(2)}</del>)}
                                        <ins>${product?.sale_price.toFixed(2)}</ins>
                                    </div>
                                    <div className="product-rating">
                                        <i className="icon-star3"></i>
                                        <i className="icon-star3"></i>
                                        <i className="icon-star3"></i>
                                        <i className="icon-star3"></i>
                                        <i className="icon-star3"></i>
                                    </div>
                                </div>
                                <div className="right-description">
                                    <AddShoppingCartOutlinedIcon onClick={() => handleAddToCart(product, product?.id, product?.name)} title="Add to cart" fontSize="large" className="add-cart-icn" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )

}

export default BestSellingContainer;