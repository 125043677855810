import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Axios from 'axios';

const RequireAuth = ({ allowedRoles }) => {
    // const { login, isAuthenticated } = useAuth();
    const location = useLocation();
    let accessToken = localStorage.getItem('accessToken');
    console.log('AccessToken in require auth: ', accessToken);
    // if (accessToken) {
    //     localStorage.setItem('accessToken', accessToken);
    //     Axios.defaults.headers.common['authorization'] = `${accessToken}`;
    // }
    return (
        accessToken ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;