import React, { useContext } from 'react';
import ShoppingCartContext from '../../Context/ShoppingCartContext';
import { ProductContext } from '../../Context/ProductContext';
import { Button, Stack } from 'react-bootstrap';
// import { formatCurrency } from "../utilities/formatCurrency"
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
const CartItem = ({ id, quantity }) => {

    const { removeFromCart } = useContext(ShoppingCartContext);
    const { productList } = useContext(ProductContext);

    const item = productList.find(i => i.id == id)
    if (item == null) return null

    return (
        <>
            <Stack direction="horizontal" gap={2} className="mini-crt-lists d-flex align-items-center">
                <Link to={'/product/' + item.slug} >
                    <img src={`https://ayusage-public.s3.us-east-2.amazonaws.com/product/${item.slug}/${item.thumbnail_1}`} alt={item.name} style={{ width: "100px", height: "75px", objectFit: "cover" }} />
                </Link>
                <div className="me-auto">
                    <div>
                        <Link to={'/product/' + item.slug} >{item.name}{" "}</Link>
                        {quantity > 0 && (
                            <span className="text-muted">
                                x {quantity}
                            </span>
                        )}
                    </div>
                    <div className="text-muted">
                        ${(item.sale_price.toFixed(2))}
                    </div>
                </div>
                <div>${(quantity * item.sale_price).toFixed(2)}</div>
                <Tooltip title={"Remove " + item.name + ' from cart'}>
                    <DeleteIcon className='dlt-icn' style={{ color: 'rgb(214 71 71)' }} onClick={() => removeFromCart(item.id)} />
                </Tooltip>

            </Stack>
            {/* <hr /> */}
        </>

    )
}

export default CartItem;