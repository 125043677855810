import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';

export const SingleBlog = () => {

    const { slug } = useParams();

    const [blogData, setSingleBlog] = useState([]);

    const getBlog = useCallback(async () => {
        const response = await axios.get('/api/v1/blog/' + slug);
        setSingleBlog(response?.data?.data?.[0]);
        //console.log(response?.data?.data?.[0]);
    });

    useEffect(() => {
        getBlog();
    }, [])

    return (
        <>
            <Helmet>
                <title>{blogData.meta_title}</title>
                <meta name="description" content={blogData.meta_description} />
                <meta property="og:title" content={blogData.meta_title} />
                <meta property="og:description" content={blogData.meta_description} />
            </Helmet>
            <section id="content">
                <div className="content-wrap p-0">
                    <div className="container clearfix">

                        <div className="row gutter-40 col-mb-80">

                            <div className="postcontent col-lg-12">

                                <div className="single-post mb-0">


                                    <div className="entry clearfix">
                                        <div className="entry-image">
                                            <img src={`/blogImg/${blogData.img}`} alt={blogData.title} />
                                        </div>

                                        <div className="entry-title">
                                            <h1>{blogData.title}</h1>
                                        </div>


                                        <div className="entry-meta">
                                            <ul>
                                                <li><i className="icon-calendar3"></i>
                                                    {moment(blogData.created_on).format('MMMM DD, YYYY')}</li>
                                                <li><i className="icon-user"></i> {blogData.posted_by}</li>
                                                <li><i className="icon-folder-open"></i> <Link to="/blog">Health</Link>, <Link to="/blog">Ayurveda</Link></li>
                                            </ul>
                                        </div>





                                        <div className="entry-content mt-0">
                                            <p dangerouslySetInnerHTML={{ __html: blogData.long_description }}></p>

                                            <div className="tagcloud clearfix bottommargin">
                                                <a href="#">Health</a>
                                                <a href="#">Ayurveda</a>
                                                <a href="#">Immunity</a>
                                                <a href="#">Nature</a>

                                            </div>

                                            <div className="clear"></div>


                                            {/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
                                                <span>Share this Post:</span>
                                                <div>
                                                    <a href="#" className="social-icon si-borderless si-facebook">
                                                        <i className="icon-facebook"></i>
                                                        <i className="icon-facebook"></i>
                                                    </a>
                                                    <a href="#" className="social-icon si-borderless si-twitter">
                                                        <i className="icon-twitter"></i>
                                                        <i className="icon-twitter"></i>
                                                    </a>
                                                    <a href="#" className="social-icon si-borderless si-pinterest">
                                                        <i className="icon-pinterest"></i>
                                                        <i className="icon-pinterest"></i>
                                                    </a>
                                                    <a href="#" className="social-icon si-borderless si-gplus">
                                                        <i className="icon-gplus"></i>
                                                        <i className="icon-gplus"></i>
                                                    </a>
                                                    <a href="#" className="social-icon si-borderless si-rss">
                                                        <i className="icon-rss"></i>
                                                        <i className="icon-rss"></i>
                                                    </a>
                                                    <a href="#" className="social-icon si-borderless si-email3">
                                                        <i className="icon-email3"></i>
                                                        <i className="icon-email3"></i>
                                                    </a>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <!-- .entry end --> */}


                                    {/* <div className="row justify-content-between col-mb-30 post-navigation">
                                        <div className="col-12 col-md-auto text-center">
                                            <a href="#">&lArr; This is a Standard post with a Slider Gallery</a>
                                        </div>

                                        <div className="col-12 col-md-auto text-center">
                                            <a href="#">This is an Embedded Audio Post &rArr;</a>
                                        </div>
                                    </div> */}



                                    <div className="card">
                                        <div className="card-header"><strong>Posted by {blogData.posted_by}</strong></div>
                                        <div className="card-body">
                                            <div className="author-image">
                                                <img src="/blogImg/Dr-vikas_kumar.jpeg" alt="Ayusage" className="rounded-circle" />

                                            </div>
                                            <p>B.A.M.S. | M.D ( Medicine)<br></br>
                                                GOLD MEDALIST<br></br>
                                                Ayurveda Physician & Consultant<br></br>
                                                Ex. Medical Officer Ayush Department, Punjab, India<br></br>
                                                Ex. Associate Professor, Deptt. Of Ayurveda Medicine.<br></br>
                                            </p>
                                            <p>Dr. Vikas Kumar Sangotra is born in the foothills of the shivalik ranges and a family where Ayurveda was in day-to-day practice but his professional journey started in the world of Ayurveda in 2003 when he started his Graduation in Ayurveda Medicine and Surgery (B.A.M.S) from one of the oldest and eminent universities in North India.

                                                Graduating with Flying colors in academics, he was awarded GOLD MEDALIST from the university, in the honorable presence of the President of India, Mr. Pranab Mukherjee.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
