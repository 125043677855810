
import AppRouting from './Router/index';

function App(props) {
  return (
    <>
      <AppRouting>
        {props.children}
      </AppRouting>

    </>

  );
}

export default App;
