import { Box, FormControl, TextField } from '@mui/material';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ConsultationForm.css'; // Custom CSS file for styling
import axios from '../../api/axios';
import { validateEmail } from '../../utils/common.utils';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useScrollToTop from "../../hooks/useScrollToTop";

function BookConsultationPage() {
    // useScrollToTop()
    const [selectedDate, setSelectedDate] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bookingNotes, setBookingNotes] = useState('');
    const [isBookingSuccessful, setIsBookingSuccessful] = useState(false);

    const [isFullNameEmpty, setIsFullNameEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isDateTimeEmpty, setIsDateTimeEmpty] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);

    const handleDateChange = (date) => {
        // Here you can handle any logic related to date change
        console.log(date);
        if (!date) {
            setIsDateTimeEmpty(true);
        } else {
            setIsDateTimeEmpty(false);
        }
        setSelectedDate(date);
    };

    const handlingFullName = (event) => {
        let fname = event.target.value;
        if (fname.trim() === '') {
            setIsFullNameEmpty(true);
        } else {
            setIsFullNameEmpty(false);
        }
        setName(fname);
    }
    const handlingEmail = (event) => {
        let email = event.target.value;
        if (validateEmail(email.trim())) {
            setIsEmailInvalid(false);
        } else if (!validateEmail(email.trim())) {
            setIsEmailInvalid(true);
        }
        if (email.trim() === '') {
            setIsEmailEmpty(true);
        } else {
            setIsEmailEmpty(false);
        }
        setEmail(email);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Here you can handle the form submission logic
        console.log('Selected Date:', selectedDate);
        console.log('Name:', name);
        console.log('Email:', email);
        if (name.trim() === '') {
            setIsFullNameEmpty(true);
            return;
        } else if (email.trim() === '') {
            setIsEmailEmpty(true);
            return;
        } else if (!validateEmail(email.trim())) {
            setIsEmailInvalid(true);
            return;
        } else if (!selectedDate || selectedDate === '') {
            setIsDateTimeEmpty(true);
            return;
        } else {
            let formData = {
                full_name: name.trim(),
                email: email.trim(),
                phone: phone.trim(),
                date_time: selectedDate,
                notes: bookingNotes.trim()
            }

            let responseData = await axios.post('/api/v1/appointment/book', formData);
            console.log('savedAppointment: ', responseData?.data);
            let getResData = responseData?.data;
            if (getResData.status === 200) {
                setIsBookingSuccessful(true);
            }
        }
    };

    // Example of disabling specific dates and times
    const isDateDisabled = (date) => {
        // Disable weekends (Saturday and Sunday)
        // return date.getDay() === 0 || date.getDay() === 5;
        return date.getDay() === 0 || date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4;
    };

    const isTimeDisabled = (time) => {
        // Disable specific times (e.g., 12:00 PM to 1:00 PM)
        const disabledStartTime = new Date();
        disabledStartTime.setHours(1, 0, 0);
        const disabledEndTime = new Date();
        disabledEndTime.setHours(15, 0, 0);

        // return time >= disabledStartTime && time < disabledEndTime;
        return time >= disabledStartTime || time < disabledEndTime;
    };

    return (<>
        <Helmet>
            <title>Book consulation | Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Book free consultation with Ayurvedic Expert and know your body type." />
            <meta property="og:title" content="Book consulation | Natural Ayurvedic Supplement Store" />
            <meta property="og:description" content="Book free consultation with Ayurvedic Expert and know your body type." />
        </Helmet>
        <section id="page-title" className='' style={{ "background": "url('./ayusage-about.jpeg')" }}>
            <div className="container">
                <h1 style={{ "color": "#FFFFFF" }}>Book Free Consultation</h1>
            </div>
        </section>
        <div className="container my-5">
            <div className="text-center mx-5 pb-5">
                <h2>Schedule Your First Consultation</h2>
                <p>Complete the provided form with your relevant details, and proceed to schedule a convenient time for a discussion. <br /> Upon successfully submitting the form, anticipate receiving a pair of emails pertaining to the confirmation of your scheduled consultation.
                </p>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='shadow p-5 consult-first'>
                        <h2>15 Minutes Initial Consultation</h2>
                        <span><i className="icon-clock"></i> 15 min</span>
                        <p><i className="icon-video"></i> Web conferencing details will be provided on confirmation.</p>
                        <p>
                            In your initial consultation, you'll have the opportunity to converse with leading Health Specialists, allowing you to openly address your concerns. Our experts are dedicated to delivering optimal healthcare services tailored to your needs.
                        </p>
                        <p> Your input is essential in ensuring that our experts are well-informed about your specific situation, enabling them to provide you with the most effective guidance and support. By sharing pertinent information through this form, you're taking the essential step of setting the stage for a fruitful conversation tailored to your needs.</p>
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <Box component="form" sx={{ '& .MuiTextField-root': { mb: 2 } }} autoComplete="off">

                        {!isBookingSuccessful && (<div className="card p-5 shadow">
                            <h2 className="text-center ">Consultation Booking Form</h2>
                            <FormControl className='col-md-12'>
                                {/* <InputLabel id="">Full Name</InputLabel> */}
                                <TextField
                                    error={isFullNameEmpty}
                                    id="outlined-basic outlined-error"
                                    label="Full Name"
                                    name="full_name"
                                    defaultValue={''}
                                    onChange={handlingFullName}
                                />
                            </FormControl>
                            <FormControl className='col-md-12'>
                                <TextField
                                    error={isEmailEmpty || isEmailInvalid}
                                    helperText={isEmailInvalid ? "Enter Valid Email" : ''}
                                    id="outlined-error"
                                    label="Email"
                                    name="email"
                                    defaultValue={''}
                                    onChange={handlingEmail}
                                />
                            </FormControl>
                            <FormControl className='col-md-12'>
                                <TextField
                                    id=""
                                    label="Phone Number"
                                    name="phone_number"
                                    defaultValue={''}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </FormControl>
                            <div className="col-md-12 mb-3 custom-datepicker">
                                <DatePicker
                                    error={isDateTimeEmpty}
                                    id="outlined-error"
                                    placeholderText='Choose Date Time'
                                    className="col-md-12 form-control"
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    minDate={new Date()}
                                    filterDate={isDateDisabled}
                                    filterTime={isTimeDisabled}
                                /> <span style={{ fontSize: '10px' }}>(IST Format)</span>
                            </div>
                            <FormControl className='col-md-12'>
                                <TextField
                                    id=""
                                    label="Notes"
                                    name="notes"
                                    defaultValue={''}
                                    onChange={(e) => setBookingNotes(e.target.value)}
                                />
                            </FormControl>
                            {/* <input type="text" name="notes" className='form-control mb-4' placeholder='NOTES' /> */}
                            <button className="button bg-dark text-white button-dark button-medium m-0" onClick={(event) => handleSubmit(event)}>Book Appointment</button>

                        </div>)}
                        {isBookingSuccessful && (
                            <div className='text-center appntmnt-bookd-layout card p-4 shadow'>
                                <div className="alert alert-success mt-3">
                                    <strong>Success!</strong> Your doctor consultation has been booked.
                                </div>
                                <p className=' my-5'>Our team will contact you shortly to provide further details and confirm the appointment. </p>
                                <p>Thank you for choosing our Ayusage!</p>
                                <p>Go to <Link to={'/'}> Home Page </Link> </p>
                            </div>

                        )}
                    </Box>
                </div>
            </div>
        </div>
    </>
    );
}

export default BookConsultationPage;
