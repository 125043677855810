import { useState, useRef, useEffect, useContext } from 'react';

import { Alert, Box, Button, FormControl, TextField } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Register from '../Dashboard/Register';

import axios from '../../../api/axios';
import { AuthContext } from '../../../Context/AuthProvider';
import useAuth from '../../../hooks/useAuth';
const LOGIN_URL = '/api/v1/auth/login';

const LoginPage = () => {

    const navigate = useNavigate();
    // const { setAuth } = useAuth();
    const { login, isAuthenticated } = useAuth();
    // console.log('isAuthenticated: ', isAuthenticated);


    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    let accTokn = localStorage.getItem('accessToken');
    useEffect(() => {
        //userRef.current.focus();
        if (accTokn) {
            navigate('/user-account/dashboard');
        }
    }, []);


    useEffect(() => {
        setErrMsg();
    }, [email, password]);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }
    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await login(email, password);

            // const response = await axios.post(LOGIN_URL,
            //     JSON.stringify({ email, password }),
            //     {
            //         headers: { 'Content-Type': 'application/json' },
            //         withCredentials: true
            //     }
            // );
            console.log('response in login page: ', response);
            // const accessToken = JSON.stringify(response?.data?.accessToken)
            // console.log('accessToken: ', JSON.stringify(response?.data?.accessToken));
            // setAuth({ email, accessToken });
            // setEmail('');
            // setPassword('');
            setSuccess(true);
            // console.log('email: ', email, 'password: ', password);
            // navigate(from, { replace: true });
            navigate('/user-account/dashboard');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else if (err.response?.status === 400) {
                setErrMsg('Missing email or password')
            } else if (err.response?.status === 401) {
                setErrMsg('Please enter correct email and password.')
            } else {
                setErrMsg('Login failed');
            }

            console.log('ERROR: something went wrong: ', err);
            // err.current.focus();
        }


    }
    // const togglePersist = () => {
    //     setPersist(prev => !prev);
    // }

    // useEffect(() => {
    //     localStorage.setItem("persist", persist);
    // }, [persist])
    return <>
        {/* <!-- Page Title-> */}
        <section id="page-title" className="page-title-mini">
            <div className="container clearfix">
                <h1>Login</h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Login</li>
                </ol>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-md-6 m-auto shadow p-5">
                            <div className="well well-lg mb-0">
                                <div className="col-12 mb-3 text-center">
                                    <h2>LOGIN</h2>
                                </div>
                                {errMsg && (<Alert className='my-4' severity="error">
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                </Alert>)}


                                <Box component="form" sx={{ '& .MuiTextField-root': { mb: 3 } }} autoComplete="off">
                                    <FormControl className='col-md-12'>
                                        {/* <InputLabel id="">Full Name</InputLabel> */}
                                        <TextField
                                            //error={isFullNameEmpty}
                                            id="outlined-basic outlined-error"
                                            label="Email"
                                            name="user_email"
                                            defaultValue={''}
                                            onChange={handleEmail}
                                        />
                                    </FormControl>
                                    {/* <input type="text" id="login-form-username" ref={userRef} name="login-form-username" required value={email} onChange={handleEmail} className="form-control" /> */}


                                    <FormControl className='col-md-12'>
                                        {/* <InputLabel id="">Full Name</InputLabel> */}
                                        <TextField
                                            //error={isFullNameEmpty}
                                            id="outlined-basic outlined-error"
                                            label="Password"
                                            type="password"
                                            name="user_password"
                                            defaultValue={''}
                                            onChange={handlePassword}
                                        />
                                    </FormControl>
                                    {/* <input type="password" required value={password} id="login-form-password" name="login-form-password" onChange={handlePassword} className="form-control" /> */}
                                    <button onClick={handleSubmit} className="button bg-dark text-white button-dark button-medium m-0">Login</button>
                                    <Link to="/forgot-password" className="float-end">Forgot Password?</Link>
                                </Box>
                                <div className="persistCheck">
                                    {/* <input
                                            type="checkbox"
                                            id="persist"
                                            onChange={togglePersist}
                                            checked={persist}
                                        /> */}
                                    {/* <label htmlFor="persist">Trust This Device</label> */}
                                </div>

                            </div>

                        </div>

                        {/* <Register /> */}
                    </div>

                </div>
                {/* <!-- Div end
          ============================================= --> */}

            </div>

        </section>

    </>
}
export default LoginPage;