import axios from 'axios';

// Create an Axios instance with the desired configuration
const api = axios.create({
    //baseURL: 'http://localhost:3001'
baseURL: process.env.LOCAL_API_URL
});

// Add a request interceptor to modify headers
api.interceptors.request.use((config) => {
    const browser = navigator.userAgent;
    config.headers['X-Browser'] = browser;

    return config;
});
export default api;
