import React, { useState } from 'react';
import axios from 'axios';

const MailchimpForm = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                'https://us5.api.mailchimp.com/3.0/lists/11e061ad05/members',
                {
                    email_address: email,
                    status: 'subscribed',
                },
                {
                    headers: {
                        Authorization: `Basic ${btoa('apikey:6cf529f3f1704bc6af86fbe29bc7d855-us5')}`,
                    },
                }
            );

            console.log(response.data);
            // Handle successful subscription
        } catch (error) {
            console.error(error);
            // Handle subscription error
        }
    };

    return (
        <div style={{ "width": "100%" }}>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Your email"
                    className='form-control sm-form-control required email mb-2'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className='button nott fw-normal ms-1 my-0' type="submit">Subscribe Now</button>
            </form>
        </div>
    );
};

export default MailchimpForm;
