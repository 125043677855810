import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const AboutPage = () => {
    return <>
        <Helmet>
            <title>About Ayusage - Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Discover natural health products on Ayusage. Know more about Ayusage and how we are proudly making naturally extracted products from organic herbs." />
        </Helmet>

        {/* <!-- Page Title-> */}
        <section id="page-title" className='' style={{ "background": "url('./ayusage-about.jpeg')" }}>
            <div className="container">
                <h1 style={{ "color": "#FFFFFF" }}>About Us</h1>
            </div>
        </section>


        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="sections row">
                        <div className="col-sm-6 col-lg-6">
                            <img src={require("../images/body-type.jpeg")} alt="Eat Healthy and Live Healthy" />
                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <h5>Eat Healthy & Live Healthy</h5>
                            <h2>WHY AYUSAGE?</h2>
                            <p>The minds behind our products are the expert who are well acquainted with the knowledge and wisdom of ayurveda. our products are specially formulated on the foundation of disease and address the root cause of your issue not merely the symptoms.
                            </p>
                            <p>Every tablet and capsule itself is the gift of nature in your hands. We provide the best ayurvedic supplements across the world that are 100% authentic product from the land of veda. Our products are based on the principle of ayurveda which works only roots of problem so to have permanet and ever lasting benefits.
                            </p>
                        </div>

                    </div>



                    {/* <!-- Mission / Vision 
          ============================================= --> */}
                    <div className="sections">
                        <div className="row">

                            <div className="col-lg-6 light col-padding overflow-hidden">

                                <h3 className="text-uppercase" style={{ "fontWeight": "600" }}>OUR VISION</h3>
                                <hr></hr>
                                <p style={{ "lineHeight": "1.8" }}>Optimum health is the greatest gift a man can have, which totally resonate with the vision of ayurveda “swasthasya swasthya rakshanam” that is keeping healthy individual healthy. Health according to ayurveda is not only limited to physical fitness but also mental as well spritual health. So our vision is to spread the richness and holyness of this 5000 years old ancient wisdom of ayurveda to the world so that people can achive the optimal health and well being through holistic and natual ways of Ayurveda.
                                </p>
                            </div>

                            <div className="col-lg-6 light col-padding overflow-hidden">
                                <h3 className="text-uppercase" style={{ "fontWeight": "600" }}>OUR MISSION</h3>
                                <hr></hr>
                                <p style={{ "lineHeight": "1.8" }}>The foundation of Ayusage's mission is based upon our vision to help people all across the world to achieve absolute balance of physical, mental and spritual health through holistic ways we fulfill our mission by producing highest quality of Ayuvedic products and supplements. our mission is to keep on formulating products based upon the principles of ayurveda which works on the root cause of the problem to have permanent and everlasting benefits.
                                </p>
                            </div>

                            <div className="clear"></div>

                        </div>
                    </div>
                    {/* <!-- Div end
          ============================================= --> */}

                </div>
            </div>
        </section>

    </>
}
export default AboutPage;