import React from 'react'
import { ProductProvider } from '../../Context/ProductContext';
import { ShoppingCartProvider } from '../../Context/ShoppingCartContext';
import BestSellingContainer from './BestSellingContainer'

const BestSellingIndex = ({ type }) => {
    return (
        <ProductProvider>
            {/* <ShoppingCartProvider> */}
            <BestSellingContainer type={type} />
            {/* </ShoppingCartProvider> */}
        </ProductProvider>


    )
}

export default BestSellingIndex;