import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// import jwt from 'jsonwebtoken';
// import { verify } from 'jsonwebtoken';
// import { JWT_SECRET } from 'src/utils/jwt';
import useAuth from '../../../src/hooks/useAuth';
// import Login from 'src/content/pages/Auth/Login/Cover';
import LoginBasic from '../../../src/component/Account/Login/index';

const Authenticated = (props) => {
    const { children } = props;
    const auth = useAuth();

    let myToken = localStorage.getItem('accessToken');
    console.log('checkToken=====>', myToken);
    function checkTokenExpired() {
        try {
            console.log('here in try clock');
            return true;
        } catch (err) {
            if (err) {
                console.log('Error in authenticated: ', err);
                return <LoginBasic />;
            }
            throw err;
        }
    }
    checkTokenExpired();
    if (myToken === null) {
        return <LoginBasic />;
    }
    // handle below later...
    // if (!auth.isAuthenticated) {
    //     return <LoginBasic />;
    // }


    return <>{children}</>;
};

Authenticated.propTypes = {
    children: PropTypes.node
};

export default Authenticated;

// import React from 'react'
// import { AuthProvider } from '../../Context/JWTAuthContext'
// import RequireAuth from './RequireAuth'

// const index = () => {
//     return (
//         <AuthProvider>
//             <RequireAuth></RequireAuth>
//         </AuthProvider>

//     )
// }

// export default index
