import axios from 'axios';
import { BASE_URL } from '../constant';

let accessToken = localStorage.getItem('accessToken');
console.log('In provate Axios request: ', accessToken);
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer_ ${accessToken}`
    }
});