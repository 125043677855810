const RecentPosts = () => {
    (
        <>
            <div className="container clearfix">

                <div className="row col-mb-50">
                    <div className="col-lg-12">
                        <div className="fancy-title title-border">
                            <h4>Recent Posts</h4>
                        </div>

                        <div className="row posts-md col-mb-30">
                            <div className="entry col-md-4">
                                <div className="grid-inner">
                                    <div className="entry-image">
                                        <a href="#"><img src="https://ayusage-public.s3.us-east-2.amazonaws.com/ayurveda-body-types.jpeg"
                                            alt="Three Body Types" /></a>
                                    </div>
                                    <div className="entry-title title-sm nott">
                                        <h3><a href="blog-single.html">Three Body Types</a></h3>
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="icon-calendar3"></i> 10th December 2022</li>
                                            <li><a href="blog-single.html#comments"><i className="icon-comments"></i>
                                                13</a></li>
                                        </ul>
                                    </div>
                                    <div className="entry-content">
                                        <p>Doshas are three biophysical forms or energies which govern all
                                            biological, physiological and psychological functions of mind and
                                            body...
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="entry col-md-4">
                                <div className="grid-inner">
                                    <div className="entry-image">
                                        <a href="#"><img src={require("../images/what_is_ayurveda.jpeg")} alt="What is Vata Dosha/Body Type
      " /></a>
                                    </div>
                                    <div className="entry-title title-sm nott">
                                        <h3><a href="blog-single.html">What is Vata Dosha/Body Type
                                        </a></h3>
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="icon-calendar3"></i> 20th July 2022</li>
                                            <li><a href="blog-single.html#comments"><i className="icon-comments"></i>
                                                13</a></li>
                                        </ul>
                                    </div>
                                    <div className="entry-content">
                                        <p>Ayurveda, a thousand years old healing science that originated in India,
                                            says that everything in this universe is a structural...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="entry col-md-4">
                                <div className="grid-inner">
                                    <div className="entry-image">
                                        <a href="#"><img src={require("../images/what_is_ayurveda.jpeg")} alt="What is Vata Dosha/Body Type
      " /></a>
                                    </div>
                                    <div className="entry-title title-sm nott">
                                        <h3><a href="blog-single.html">What is Vata Dosha/Body Type
                                        </a></h3>
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="icon-calendar3"></i> 20th July 2022</li>
                                            <li><a href="blog-single.html#comments"><i className="icon-comments"></i>
                                                13</a></li>
                                        </ul>
                                    </div>
                                    <div className="entry-content">
                                        <p>Ayurveda, a thousand years old healing science that originated in India,
                                            says that everything in this universe is a structural...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default RecentPosts;