import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';

function TopBar() {
    return (
        <>

            <div className="top-links">
                <ul className="top-links-container">
                    <li className="top-links-item"><NavLink to="/about">About</NavLink></li>
                    <li className="top-links-item"><NavLink to="/shop">Shop</NavLink></li>
                    <li className="top-links-item"><NavLink to="/contact">Contact</NavLink></li>
                </ul>
            </div>

        </>
    );
}
export default TopBar;