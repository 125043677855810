import React from 'react'
import { AuthProvider } from '../../../Context/JWTAuthContext'
import RequireAuth from '../../Authenticated/RequireAuth';
import LoginContainer from './LoginContainer';

const index = () => {
    return (
        <AuthProvider>
            <LoginContainer></LoginContainer>
        </AuthProvider>

    )
}

export default index