import React, { useState } from 'react';

function Tabs(props) {
    const [activeTab, setActiveTab] = useState(0);

    // console.log("hi", props);
    const handleTabClick = (index) => {
        setActiveTab(index);
    }

    return (
        <div className="container">
            <ul className="nav nav-tabs tab-nav">
                {props.tabs.map((tab, index) => (
                    <li key={index} className="nav-item">
                        <a
                            className={`nav-link ${index === activeTab ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}
                        >

                            {tab && tab.id == 1 && props.product.long_description && (<i className="icon-align-justify2"></i>)}
                            {tab && tab.id == 2 && (<i className="icon-info-sign"></i>)}
                            {tab && tab.id == 3 && (<i className="icon-thumbs-up2"></i>)}
                            {/* {tab && tab.id == 4 && (<i className="icon-star3"></i>)} */}

                            <span className='d-none d-md-inline-block'>
                                {tab.label}
                            </span>
                        </a>
                    </li>
                ))}
            </ul>
            <div className="tab-content">
                {props.tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab-pane ${index === activeTab ? 'active show' : ''}`}
                    >
                        {tab && tab.id == 1 && (<p dangerouslySetInnerHTML={{ __html: props.product.long_description }}></p>)}
                        {tab && tab.id == 2 && (<div><h4>Ingredients</h4><p dangerouslySetInnerHTML={{ __html: props.product.ingredient }}></p></div>)}
                        {tab && tab.id == 3 && (<div><h4>Benefits</h4><p dangerouslySetInnerHTML={{ __html: props.product.benefits }}></p></div>)}
                    </div>
                ))}
            </div>
        </div >
    );
}

export default Tabs;