import React from 'react';
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import RootLayout from '../pages/Root'

import HomeContainer from "../component/Home/HomeContainerIndex";
import AboutPage from "../pages/About";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ContactPage from "../pages/ContactPage";
import BodyTypePage from "../pages/Dosha/BodyType";
import BookConsultationPage from "../pages/BookConsultation/BookConsultation";
import ShopPage from "../component/Product/Shop";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import ReturnPolicyPage from "../pages/ReturnPolicy";
import TermsConditionsPage from "../pages/TermsConditions";
import FaqsPage from "../pages/Faq/Faqs";
import MyAccountPage from "../pages/MyAccount";
import DoshaPage from "../pages/Dosha";
// import CartPage from "../pages/Cart";
import MainCart from "../component/MainCart/MainCartIndex";
// import CheckoutPage from "../pages/Checkout";
import OrderPlaced from "../pages/Orderplaced";
import SubmitKnowYourBodyType from "../pages/SubmitKnowYourBodyType";
import ProductPage from "../component/Product/index";
import LoginPage from "../component/Account/Login/index";
import ForgotPasswordPage from "../component/Account/Forgot";
// import DashboardContainer from "../component/Account/Dashboard/Index";
import UserAccountRootLayout from "../pages/UserAccountRootLayout";
import DashboardContainer from "../component/Account/Dashboard/DashboardIndex";
import PersistLogin from "../component/PersistLogin";
import RequireAuth from "../component/Authenticated/RequireAuth";
import Authenticated from "../component/Authenticated";
import { ShoppingCartProvider } from "../Context/ShoppingCartContext";
import { useState } from "react";

import CheckoutIndex from "../pages/Checkout/CheckoutIndex";
import ProtectedRoute from "./ProtectedRoutes";
import AddressContainer from '../component/Account/Address/AddressContainer';
import Return from '../component/Account/Return/ReturnComponent';
import OrderList from '../component/Account/Order/OrderList';
import ChangePassword from '../component/Account/ChangePassword/ChangePassword';
import { SingleBlog } from '../pages/Blog/SingleBlog';
import BlogPage from '../pages/Blog/Blog';
import QuizComponent from '../pages/Dosha/QuizComponent';
import ShippingDeliveryPolicy from '../pages/shipping-delivery';

const router = createBrowserRouter(
    [
        {
            path: '/user-account',
            // element: <Authenticated>
            //     <UserAccountRootLayout></UserAccountRootLayout>
            // </Authenticated>,
            element:
                <Authenticated>
                    <UserAccountRootLayout></UserAccountRootLayout>
                </Authenticated>,
            // <RequireAuth>
            //     <UserAccountRootLayout></UserAccountRootLayout>
            // </RequireAuth>,
            errorElement: <ErrorPage />,
            // children: [
            //     {
            //         path: 'dashboard',
            //         element: <ProfileContainer />
            //     },
            //     {
            //         path: 'profile',
            //         element: <ProfileContainer />
            //     }
            // ]
        },
        {
            path: '/',
            element: <RootLayout />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '',
                    element: <HomeContainer />
                },
                // {
                //     path: 'about',
                //     element: <AboutPage />
                // },
                // {
                //     path: 'blog',
                //     element: <BlogPage />
                // },
                // {
                //     path: 'contact',
                //     element: <ContactPage />
                // },
                // {
                //     path: 'bodytype',
                //     element: <BodyTypePage />
                // },
                // {
                //     path: 'consultation',
                //     element: <BookConsultationPage />
                // },
                // {
                //     path: 'shop',
                //     element: <ShopPage />
                // },
                // {
                //     path: 'privacy-policy',
                //     element: <PrivacyPolicyPage />
                // },
                // {
                //     path: 'return-policy',
                //     element: <ReturnPolicyPage />
                // },
                // {
                //     path: 'terms-conditions',
                //     element: <TermsConditionsPage />
                // },
                // {
                //     path: 'faq',
                //     element: <FaqsPage />
                // },
                // {
                //     path: 'account',
                //     element: <MyAccountPage />
                // },
                // {
                //     path: 'dosha',
                //     element: <DoshaPage />
                // },
                // {
                //     path: 'product/:product_id',
                //     element: <ProductPage />
                // },
                // {
                //     path: 'cart',
                //     element: <MainCart />
                // },

                // {
                //     path: 'order-placed',
                //     element: <OrderPlaced />
                // },
                // {
                //     path: 'login',
                //     element: <LoginPage />
                // },
                // {
                //     path: 'forgot-password',
                //     element: <ForgotPasswordPage />
                // },
                // {
                //     path: 'user-dashboad',
                //     element: <DashboardContainer />
                // },
                // {
                //     path: '*',   // when page doesnot exit..
                //     element: <ErrorPage />
                // }
            ]
        }
    ]
);

const AppRouting = () => {
    let token = localStorage.getItem('accessToken');
    // const [isAuthenticated, setIsAuthenticated] = useState(token || false);


    return <>
        {/* <BrowserRouter> */}
        <Routes>
            <Route path="/" element={<RootLayout />}>
                {/* <Route path="/login" element={<LoginPage />} /> */}
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />

                <Route element={<RequireAuth />} >
                    <Route path="/user-account/dashboard" element={<DashboardContainer />} />
                    <Route path="/user-account/address" element={<AddressContainer />} />
                    <Route path="/user-account/orders" element={<OrderList />} />
                    <Route path="/user-account/return" element={<Return />} />
                    <Route path="/user-account/change-password" element={<ChangePassword />} />
                </Route>
                <Route index element={<HomeContainer />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/faq" element={<FaqsPage />} />
                <Route path="/return-policy" element={<ReturnPolicyPage />} />
                <Route path="/terms-conditions" element={<TermsConditionsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/product/:product_id" element={<ProductPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:slug" element={<SingleBlog />} />
                <Route path="/bodytype" element={<BodyTypePage />} />
                <Route path="/what-is-dosha" element={<DoshaPage />} />
                <Route path="/consultation" element={<BookConsultationPage />} />
                <Route path="/cart" element={<MainCart />} />
                <Route path="/shipping-delivery" element={<ShippingDeliveryPolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/checkout" element={<CheckoutIndex />} />
                <Route path="/order-placed" element={<OrderPlaced />} />
                <Route path="/thank-you" element={<OrderPlaced />} />
                <Route path="/submit-know-your-body-type" element={<SubmitKnowYourBodyType />} />
                <Route path="/quiz-component" element={<QuizComponent />} />
                <Route path="*" element={<ErrorPage />} />
            </Route>
        </Routes>
        {/* </BrowserRouter> */}

    </>
}

export default AppRouting;