import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Modal, TextField } from "@mui/material";

import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import HowToRegIcon from '@mui/icons-material/HowToReg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShopIcon from '@mui/icons-material/Shop';
import AddTaskIcon from '@mui/icons-material/AddTask';

import { ProductContext } from "../../Context/ProductContext";
import ShoppingCartContext from "../../Context/ShoppingCartContext";
import MainCartItem from "./MainCartItem";
import './Index.css';
import axios from "../../api/axios";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useCart } from "../../Context/CartContext";
import RecommendationProducts from "../RecommendationProducts/RecommendationProducts";


const MainCartContainer = (props) => {
    useScrollToTop();
    let navigate = useNavigate();

    const { state, dispatch, calculateSubtotal, calculateTotal } = useCart();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openReferalEmail, setOpenReferalEmail] = useState(false);
    const handleEmailForReferalOpen = () => setOpenReferalEmail(true);
    const handleEmailForReferalClose = () => setOpenReferalEmail(false);


    const { cartItems } = useContext(ShoppingCartContext);
    const { productList } = useContext(ProductContext);

    const [shippingPrice, setShippingPrice] = useState(9.00);
    const [totalHST, setTotalHST] = useState(13.00);
    const [isShippingFree, setIsShippingFree] = useState(false);

    const [couponInvalidMessage, setCouponInvalidMessage] = useState('');
    const [couponData, setCouponData] = useState({});
    const [couponCode, setCouponCode] = useState();
    const [referalEmail, setReferalEmail] = useState('');
    const [loadingPayButton, setLoadingPayButton] = useState(false);
    const [finalSubtotal, setFinalSubtotal] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);


    // Inside your useEffect:
    useEffect(() => {
        const newSubTotal = calculateSubtotal(cartItems, productList);

        // Determine if shipping is free and set the shipping price accordingly
        const newIsShippingFree = newSubTotal > 49;
        const newShippingPrice = newIsShippingFree ? 0 : 9.0;

        dispatch({ type: "SET_CART_ITEMS", payload: cartItems });
        dispatch({ type: "SET_FINAL_SUBTOTAL", payload: newSubTotal.toFixed(2) });

        // Update shipping and shipping free status
        dispatch({ type: "SET_SHIPPING_PRICE", payload: newShippingPrice });
        dispatch({ type: "SET_IS_SHIPPING_FREE", payload: newIsShippingFree });

        // Recalculate the total
        calculateTotal();
        // ... other calculations and dispatch actions
    }, [cartItems, productList]);


    const calculateHST = () => {
        // Calculate HST based on the final subtotal
        return ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
    };

    const calculateFinalTotal = () => {
        let calHst = ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
        let shiping = state.isShippingFree ? 0.00 : state.shippingPrice;
        // Calculate HST based on the final subtotal
        return ((parseFloat(state.finalSubtotal) + parseFloat(calHst)) + parseFloat(shiping)).toFixed(2);
    };
    const calculateFinalTotalAfterDiscount = () => {
        let calHst = ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
        let shiping = state.isShippingFree ? 0.00 : state.shippingPrice;
        // Calculate HST based on the final subtotal
        let totalAfterDiscount = ((parseFloat(state.finalSubtotal) + parseFloat(calHst)) + parseFloat(shiping)).toFixed(2);
        return (totalAfterDiscount - discountAmount).toFixed(2);
    };

    // const initialState = {
    //     subTotal: 0,
    // };

    // function reducer(state, action) {
    //     switch (action.type) {
    //         case 'SET_SUBTOTAL':
    //             return { ...state, subTotal: action.payload };
    //         default:
    //             return state;
    //     }
    // }

    // const [state, dispatch] = useReducer(reducer, initialState);

    // useEffect(() => {

    //     const newSubTotal = cartItems.reduce((total, cartItem) => {
    //         const item = productList?.find(i => i.id == cartItem.id);
    //         return total + (item?.sale_price || 0) * cartItem?.quantity;
    //     }, 0);

    //     if (newSubTotal > 49) {
    //         setIsShippingFree(true);
    //     } else if (newSubTotal < 49) {
    //         setIsShippingFree(false);
    //     }
    //     let newSubtotalAfterCoupon;
    //     if (couponInvalidMessage && couponInvalidMessage == 'valid') {
    //         console.log('couponData ', couponData);
    //         let afterCoupon;
    //         if (couponData && couponData.type === "flat") {
    //             afterCoupon = newSubTotal - couponData?.amount;
    //         } else if (couponData?.type === 'percent') {
    //             afterCoupon = (newSubTotal * couponData?.amount) / 100;
    //             console.log('afterCoupon: ', afterCoupon);
    //             afterCoupon = newSubTotal - afterCoupon;
    //         } else {
    //             setCouponInvalidMessage('invalid');
    //             afterCoupon = newSubTotal
    //         }

    //         newSubtotalAfterCoupon = afterCoupon >= 0 ? afterCoupon : 0;
    //     } else {
    //         newSubtotalAfterCoupon = newSubTotal;
    //     }
    //     setFinalSubtotal(newSubtotalAfterCoupon.toFixed(2));
    //     dispatch({ type: 'SET_SUBTOTAL', payload: newSubtotalAfterCoupon.toFixed(2) });
    // }, [cartItems, productList, couponInvalidMessage]);

    let finalPrice = 0;
    // const subTotalPrice = useMemo((isValidCoupon) => {
    //     return (parseFloat(state.subTotal)).toFixed(2);
    // }, [state.subTotal, shippingPrice, couponInvalidMessage]);

    // const finalTotalPrice = (subtotal, hst, shipping) => {
    //     if (isShippingFree) {
    //         shipping = 0;
    //     }
    //     finalPrice = ((parseFloat(subtotal) * hst / 100) + parseFloat(subtotal) + parseFloat(shipping)).toFixed(2)
    //     return finalPrice;
    // };

    // const proceedCheckout = () => {
    //     navigate('/checkout');
    // }

    const handleSetCouponCode = (event) => {
        const cpn_code = event.target.value.trim();
        setCouponCode(cpn_code);
    }
    const handleSetReferalEmail = (event) => {
        const refrl_email = event.target.value.trim();
        setReferalEmail(refrl_email);
    }
    const handleCouponCode = debounce(async () => {
        // const cpn_code = event.target.value.trim();
        let sendData = {
            coupon_code: couponCode
        }
        let response = await axios.post('/api/v1/verifyCoupon', sendData);
        let data = response?.data?.data;
        console.log(data);
        if (!data) {
            console.log('Not valid..');
            setCouponInvalidMessage('invalid');
        } else {
            setCouponData(data);
            if (data.type === 'percent') {
                // let totalDiscoiuntApplied = Number(state.finalSubtotal) * data.amount / 100;
                // setDiscountAmount(totalDiscoiuntApplied);
                let totalDiscountApplied = Number(state.finalSubtotal) * data.amount / 100;
                let formattedDiscountAmount = Number(totalDiscountApplied.toFixed(2));
                setDiscountAmount(formattedDiscountAmount.toFixed(2));
            } else {
                setDiscountAmount(data.amount);
            }


            setCouponInvalidMessage('valid');
        }
    }, 1000);

    const freeShippingRemainingAmount = () => {
        const remainingAmount = 49 - state.finalSubtotal;
        // const roundedAmount = Math.ceil(remainingAmount);
        return remainingAmount.toFixed(2);
    }

    //Razor pay integration: ....

    const checkoutHandler = async () => {
        setLoadingPayButton(true);
        const { data: { key } } = await axios.post('/api/v1/payment/razorPayKey');
        const { data: { order } } = await axios.post('/api/v1/payment/checkout', {
            amount: calculateFinalTotal()
        });

        const options = {
            key,
            amount: order.amount,
            currency: "CAD",
            name: "Ayusage Pvt. Ltd.",
            description: "Ayusage Private Limited",
            image: "./ayusage-logo.png",
            order_id: order.id,
            callback_url: process.env.REACT_APP_LOCAL_API_URL + '/api/v1/payment/razorpayVerification',
            prefill: {
                name: "",
                email: "",
                contact: ""
            },
            notes: {
                address: ""
            },
            theme: {
                color: "#7cc144"
            }
        };
        const razor = new window.Razorpay(options);
        razor.open();
        setLoadingPayButton(false);
    }

    const handleSendReferalCode = async () => {
        console.log('handleSendReferalCode: ', referalEmail);
    }
    const handleCheckout = async () => {
        navigate(`/checkout`)
    }
    return <>
        <div className="container">
            <div className='content-wrap'>
                {cartItems && cartItems.length ? (
                    <div className="row">
                        <div className="col-md-7">
                            <h3>Cart Items</h3>
                            <table className="table cart mb-5 crt-itm">
                                <thead>
                                    <tr>
                                        <th className="cart-product-remove">&nbsp;</th>
                                        <th className="cart-product-thumbnail">Image&nbsp;</th>
                                        <th className="cart-product-name">Product</th>
                                        <th className="cart-product-price">Unit Price</th>
                                        <th className="cart-product-quantity">Quantity</th>
                                        <th className="cart-product-subtotal">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems && cartItems.map(item => (
                                        <MainCartItem key={item.id} {...item} />))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-1 vertical-lines"></div>
                        <div className='col-md-4 pt-1'>
                            <div className="table-responsive pt-5">
                                <div className="coupon-div">
                                    <FormControl className='col-md-8 pt-1'>
                                        <TextField
                                            id="outlined-error"
                                            label="Coupon Code"
                                            name="coupon_code"
                                            defaultValue={''}
                                            onChange={handleSetCouponCode}
                                            InputProps={{
                                                placeholder: '',
                                            }}
                                        />
                                    </FormControl>
                                    <button onClick={handleCouponCode} className="button bg-dark text-white button-dark button-medium ms-0">SUBMIT</button>

                                    {couponInvalidMessage === 'invalid' && (<Alert severity="warning">Opps, Invalid Coupon.  <span style={{ cursor: 'pointer' }} onClick={handleOpen}>No worries <ArrowRightAltIcon /> Get Coupon</span></Alert>)}
                                    {couponInvalidMessage === 'valid' && (<Alert severity="success">Congratulation, Coupon Applied.</Alert>)}
                                </div>
                                <table className="table cart cart-totals  crt-itms">
                                    <tbody>
                                        <tr className="cart_item">
                                            <td className="cart-product-name">
                                                <strong>Cart Subtotal</strong>
                                            </td>
                                            <td className="cart-product-result">
                                                {/* ${subTotalPrice} */}
                                                ${state.finalSubtotal}
                                            </td>
                                        </tr>
                                        <tr className="cart_item">
                                            <td className="cart-product-name">
                                                <strong>Shipping</strong>
                                            </td>

                                            <td className="cart-product-result">
                                                {/* {isShippingFree ? <span className="amount">Free Delivery</span> : '$' + shippingPrice + '.00'} */}
                                                {state.isShippingFree ? (
                                                    <span className="amount">Free Delivery</span>
                                                ) : (
                                                    `$${state.shippingPrice}.00`
                                                )}
                                            </td>
                                        </tr>
                                        <tr className="cart_item">
                                            <td className="cart-product-name">
                                                <strong>HST %</strong>
                                            </td>

                                            <td className="cart-product-result">
                                                {/* ${(parseFloat(subTotalPrice) * totalHST / 100).toFixed(2)} */}
                                                ${calculateHST()}
                                            </td>
                                        </tr>
                                        <tr className="cart_item">
                                            <td className="cart-product-name">
                                                <strong>Total</strong> <br />
                                                <strong>Discount</strong><br />
                                                <strong>Final Total</strong>


                                            </td>

                                            <td className="cart-product-result">
                                                <span className="amount color lead">
                                                    ${calculateFinalTotal()}
                                                    <br />
                                                    {discountAmount ? (<span>${discountAmount}</span>) : <span style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} onClick={handleOpen}>No Discount applied. Get Coupon</span>}
                                                    <br />
                                                    ${calculateFinalTotalAfterDiscount()}
                                                </span>

                                                <Dialog
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Below different way to get free coupon"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <p>
                                                                <ArrowRightAltIcon />Newsletter Signup: Subscribe to ayusage newsletter from bottom right of page. <br />

                                                            </p>
                                                            <p>
                                                                <ArrowRightAltIcon />Register: Signup first time to get coupon code. <br />
                                                                <span style={{ cursor: 'pointer', color: "#7CC144" }} >Click here to register  </span>
                                                            </p>
                                                            <p>
                                                                <ArrowRightAltIcon />
                                                                Referal: Refer to your friend, after the purchange of minimum amount $50 by using your referral code, then you are eligible to claim your coupon. <br />
                                                                <span style={{ cursor: 'pointer', color: "#7CC144" }} onClick={handleEmailForReferalOpen}> Generate Referal Code </span>
                                                            </p>
                                                            {/* <p><ArrowRightAltIcon /> Consecutive purchage: Once you purcharge 3 times in 6 month (No. minimum amount) then you will get the special discount. </p>
                                                            <p><ArrowRightAltIcon />Prime member: To become ayusage prime member you need to do purchage of minimum $200. </p> */}

                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleClose}>Close</Button>
                                                    </DialogActions>
                                                </Dialog>
                                                <Dialog
                                                    open={openReferalEmail}
                                                    onClose={handleEmailForReferalClose}
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Enter your email address to receive the coupon."}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            To get referral code, please enter your email address here.We will send you referral code over email.
                                                        </DialogContentText>
                                                        <TextField
                                                            autoFocus
                                                            required
                                                            margin="dense"
                                                            id="name"
                                                            name="email"
                                                            label="Email Address"
                                                            type="email"
                                                            fullWidth
                                                            variant="standard"
                                                            onChange={handleSetReferalEmail}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleEmailForReferalClose}>Cancel</Button>
                                                        <Button onClick={handleSendReferalCode}>Send</Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {state.finalSubtotal < 49 && (<div className='add-more-amount'><span >*</span>Add <b>${freeShippingRemainingAmount()}</b>  more to get Free Shipping</div>)} <br />
                                {/* <button style={{ cursor: 'pointer' }} className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4" onClick={proceedCheckout}>Proceed For Checkout</button> */}
                                <div>
                                    {loadingPayButton ? <button style={{ cursor: 'pointer' }} className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4">{loadingPayButton ? <span>Loading...</span> : ''}</button> :
                                        <button style={{ cursor: 'pointer' }} className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4" onClick={() => handleCheckout()} >Checkout </button>}
                                </div>
                            </div>
                        </div>
                        <RecommendationProducts></RecommendationProducts>
                    </div>) :
                    <>
                        <div className="text-center">
                            <h2>No product added</h2>
                            Click<Link to={'/shop'}> here to shop</Link>
                        </div>

                    </>
                }
            </div>
        </div>
    </>
}
export default MainCartContainer;