import { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Card } from "react-bootstrap";
import Tabs from '../../component/Tabs';
import { ProductContext } from '../../Context/ProductContext';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SingleProductPage.css';

import BestSellingContainer from '../../component/BestSelling/BestSellingContainer';
import ShoppingCartContext from "../../Context/ShoppingCartContext";
import axios from "../../api/axios";
import useScrollToTop from "../../hooks/useScrollToTop";
import { Tooltip } from "@mui/material";
import { ArrowDownwardOutlined, ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import { Helmet } from "react-helmet";

function SingleProduct() {
    useScrollToTop();
    let navigate = useNavigate();
    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useContext(ShoppingCartContext)
    const [product, setProduct] = useState([]);

    const { product_id } = useParams();

    const quantity = getItemQuantity(product?.id);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const productImages = [
        product?.main_image,
        product?.thumbnail_1,
        product.thumbnail_2
        // ...more images
    ];
    // TABS
    const tabs = [
        { id: 1, label: 'Description', content: "Description" },
        { id: 2, label: 'Ingredients List', content: 'Ingredient List' },
        { id: 3, label: 'Benefits', content: 'Benefits List' },
        // { id: 4, label: 'Reviews', content: 'This is the content of Tab 3' },
    ];


    // Make an API call to retrieve the single product data
    const fetchProductList = useCallback(async () => {
        const response = await axios.get('/api/v1/product/' + product_id);
        if (response && response.data && response.data.data) {
            console.log(response?.data.data[0]);
            setProduct(response?.data.data[0]);
        }
    }, []);
    useEffect(() => {
        fetchProductList()

    }, [fetchProductList]);

    const goToCart = () => {
        navigate('/cart');
    }

    // Render the product data
    return (
        <>
            <Helmet>
                <title>{product.meta_title}</title>
                <meta name="description" content={product.meta_description} />
                <meta property="og:title" content={product.meta_title} />
                <meta property="og:description" content={product.meta_description} />
            </Helmet>
            {product && (
                <section id="content">
                    <div className="content-wrap">
                        <div className="container clearfix">
                            <div className="single-product">
                                <div className="product">
                                    <div className="row gutter-40">
                                        <div className="col-md-5">
                                            {/* <!-- Product Single Image - 
                                ============================================= --> */}
                                            <Slider {...sliderSettings}>
                                                {productImages.map((image, index) => (
                                                    <div key={index} className="zoom-image">
                                                        <img src={`https://ayusage-public.s3.us-east-2.amazonaws.com/product/${product.slug}/${image}`} alt={product.name} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>

                                        <div className="col-md-5 product-desc">
                                            <h1 className="product-title">{product.name}
                                                {product?.sale_price < product?.price && <span className="badge bg-danger" style={{ float: 'right', fontSize: '13px' }}>Sale!</span>}
                                            </h1>
                                            <div className="d-flex align-items-center justify-content-between">

                                                <div className="product-price">{product.sale_price < product.price && (<del> ${product.sale_price}</del>)} <ins>${product.price}</ins></div>

                                                <div className="product-rating">
                                                    <i className="icon-star3"></i>
                                                    <i className="icon-star3"></i>
                                                    <i className="icon-star3"></i>
                                                    <i className="icon-star3"></i>
                                                    <i className="icon-star-half-full"></i>
                                                    <span>({product?.rating || 12})</span>
                                                </div>

                                            </div>

                                            <div className="line"></div>

                                            {/* <!-- Product Single - Short Description
                                ============================================= --> */}
                                            <p dangerouslySetInnerHTML={{ __html: product.short_description }}></p>

                                            <div className="line"></div>

                                            {/* <!-- Product Single - Quantity & Cart Button
                                ============================================= --> */}
                                            {/* <form className="cart mb-0 d-flex justify-content-between align-items-center" method="post" encType='multipart/form-data'>
                                                <div className="quantity clearfix">
                                                    <strong className="m-2">Quantity: </strong>

                                                    <input type="number" value={quantity} onChange={handleQuantityChange} className="m-1 qty" />

                                                    <input type="button" value="-" className="minus" />
                                            <input type="number" step="1" min="1" name="quantity" value="1" title="Qty" className="qty" />
                                            <input type="button" value="+" className="plus" />
                                                </div>
                                                <button type="submit" className="add-to-cart button m-0">Add to cart</button>

                                            </form> */}

                                            <div className="mt-auto">
                                                {quantity == 0 ? (
                                                    <Button className="w-100 button nott fw-normal" onClick={() => increaseCartQuantity(product?.id)}>+ Add to Cart</Button>
                                                ) : <div className="d-flex align-items-center flex-column" style={{
                                                    gap:
                                                        ".5rem"
                                                }}>
                                                    <div className="d-flex align-items-center justify-content-center " style={{
                                                        gap:
                                                            ".5rem"
                                                    }}>
                                                        <Tooltip className='up-down-arrw' title={"Decrease quantity"}>
                                                            <ArrowDownwardOutlined onClick={() => decreaseCartQuantity(product?.id)} />
                                                        </Tooltip>
                                                        <div>
                                                            <span className="fs-3">{quantity}</span> in cart
                                                        </div>
                                                        <Tooltip className='up-down-arrw' title={"Decrease quantity"}>
                                                            <ArrowUpwardRounded onClick={() => increaseCartQuantity(product?.id)} />
                                                        </Tooltip>
                                                    </div>
                                                    <div className="d-flex ">
                                                        <Button variant="danger" className="button nott fw-normal ms-1 my-0" size="sm" onClick={() => removeFromCart(product?.id)}>Remove</Button>
                                                        <span className="go-to-cart-icon"><Button
                                                            color="inherit"
                                                            variant="outlined"
                                                            onClick={goToCart}
                                                            sx={{ m: 5 }}
                                                        >View Cart <ShoppingCartCheckoutOutlinedIcon /></Button></span>
                                                    </div>

                                                </div>
                                                }
                                            </div>

                                            <div className="line"></div>


                                            {/* <!-- Product Single - EXTRA
                                ============================================= --> */}
                                            <div className="card product-meta">
                                                <div className="d-flex flex-row justify-content-around">
                                                    <span className="p-3"> <i className="icon-check-circle"></i> Gluten Free</span>
                                                    <span className="p-3"><i className="icon-check-circle"></i> Heavy Metal Free</span>
                                                    <span className="p-3"><i className="icon-check-circle"></i> GMP Free</span>

                                                </div>
                                                <div className="d-flex flex-row justify-content-around">
                                                    <span className="p-3"><i className="icon-check-circle"></i> No Artifical</span>
                                                    <span className="p-3"><i className="icon-check-circle"></i> Laboratory Tested</span>
                                                    <span className="p-3"><i className="icon-check-circle"></i> 100% Organic</span>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">

                                            <div className="feature-box fbox-plain fbox-dark fbox-sm">
                                                <div className="fbox-icon">
                                                    <i className="icon-thumbs-up2"></i>
                                                </div>
                                                <div className="fbox-content fbox-content-sm">
                                                    <h3>100% Original</h3>
                                                    <p className="mt-0">We guarantee you 100% organic healthy supplements.</p>
                                                </div>
                                            </div>

                                            <div className="feature-box fbox-plain fbox-dark fbox-sm mt-4">
                                                <div className="fbox-icon">
                                                    <i className="icon-credit-cards"></i>
                                                </div>
                                                <div className="fbox-content fbox-content-sm">
                                                    <h3>Payment Options</h3>
                                                    <p className="mt-0">We accept Visa, MasterCard, and American Express.</p>
                                                </div>
                                            </div>

                                            <div className="feature-box fbox-plain fbox-dark fbox-sm mt-4">
                                                <div className="fbox-icon">
                                                    <i className="icon-truck2"></i>
                                                </div>
                                                <div className="fbox-content fbox-content-sm">
                                                    <h3>Free Shipping</h3>
                                                    <p className="mt-0">Free Delivery for orders above $49.</p>
                                                </div>
                                            </div>

                                            <div className="feature-box fbox-plain fbox-dark fbox-sm mt-4">
                                                <div className="fbox-icon">
                                                    <i className="icon-undo"></i>
                                                </div>
                                                <div className="fbox-content fbox-content-sm">
                                                    <h3>30-Days Returns</h3>
                                                    <p className="mt-0">Return or exchange items purchased within 30 days. <a href="/return-policy" >Return Policy</a></p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="w-100"></div>

                                        <div className="col-12 mt-5">

                                            <Tabs tabs={tabs} product={product} />

                                        </div>
                                        <div className="line"></div>

                                        <div className="row product-extra-decription">

                                            <div className="col-md-7 mt-3">
                                                <h2>{product.name} Benefits</h2>
                                                <div className="extra-descrp">
                                                    <p dangerouslySetInnerHTML={{ __html: product.extra_description }}></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-2 offset-1">
                                                {productImages && productImages[1] && (<img alt={product.name} src={`https://ayusage-public.s3.us-east-2.amazonaws.com/product/${product.slug}/${productImages[1]}`} />)}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="line"></div>

                            <div className="w-100">

                                <h4>Related Products</h4>
                                <BestSellingContainer />

                            </div>
                            <div className="line"></div>

                        </div>
                    </div>
                </section>
            )
            }

        </>
    );
};


export default SingleProduct;