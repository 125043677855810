import React, { useContext, useEffect } from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasTitle, Stack } from 'react-bootstrap'
import ShoppingCartContext from '../../Context/ShoppingCartContext';

import './Index.css';

import CartItem from './CartItem';
import { Link, useNavigate } from 'react-router-dom';
import { ProductContext } from '../../Context/ProductContext';
import { Alert, Snackbar } from '@mui/material';
import { useCart } from '../../Context/CartContext';
import { red } from '@mui/material/colors';

const MiniCartContainer = (props) => {
    let isOpen = props.isOpen;
    const { closeCart, cartItems, latestProductAdded } = useContext(ShoppingCartContext);
    const { productList } = useContext(ProductContext);
    // console.log('Latest Product Added: ', latestProductAdded);
    const navigate = useNavigate();

    const { state, dispatch, calculateSubtotal, calculateTotal } = useCart();

    const goToCart = () => {
        closeCart();
        navigate("/cart");
    }
    const goToCheckout = () => {
        closeCart();
        navigate("/checkout");
    }
    const goToShopPage = () => {
        navigate("/shop");
        closeCart();
    }
    useEffect(() => {
        const newSubTotal = calculateSubtotal(cartItems, productList);

        // Determine if shipping is free and set the shipping price accordingly
        const newIsShippingFree = newSubTotal > 49;
        const newShippingPrice = newIsShippingFree ? 0 : 9.0;

        dispatch({ type: "SET_CART_ITEMS", payload: cartItems });
        dispatch({ type: "SET_FINAL_SUBTOTAL", payload: newSubTotal.toFixed(2) });

        // Update shipping and shipping free status
        dispatch({ type: "SET_SHIPPING_PRICE", payload: newShippingPrice });
        dispatch({ type: "SET_IS_SHIPPING_FREE", payload: newIsShippingFree });

        // Recalculate the total
        // calculateTotal();
        // ... other calculations and dispatch actions
    }, [cartItems, productList]);

    const calculateHST = () => {
        // Calculate HST based on the final subtotal
        return ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
    };
    const freeShippingRemainingAmount = () => {
        const remainingAmount = 49 - calTotalPrice();
        // const roundedAmount = Math.ceil(remainingAmount);
        return remainingAmount.toFixed(2);
    }
    const calTotalPrice = () => {
        return cartItems.reduce((total, cartItem) => {
            const item = productList?.find(i => i.id === cartItem.id);
            return total + (item?.sale_price || 0) * cartItem?.quantity;
        }, 0).toFixed(2);
    }
    const calculateFinalTotal = () => {
        let calHst = ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
        let shiping = state.isShippingFree ? 0.00 : state.shippingPrice;
        // Calculate HST based on the final subtotal
        return ((parseFloat(state.finalSubtotal) + parseFloat(calHst)) + parseFloat(shiping)).toFixed(2);
    };
    return (
        <>
            {latestProductAdded && latestProductAdded?.name && (<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isOpen} autoHideDuration={5000} onClose={closeCart}>
                <Alert onClose={closeCart} severity="success" sx={{ width: '100%' }}>
                    {latestProductAdded?.name} added successfully!
                </Alert>
            </Snackbar>)}


            <div className={`offcanvas-container ${isOpen ? 'show' : ''}`}>
                <Offcanvas show={isOpen} onHide={closeCart} placement="end" className="mini-cart-cls">

                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    {cartItems.length > 0 && (
                        <>
                            <Offcanvas.Title>
                                <div className='my-1 text-center'>
                                    <p >
                                        <span className='shping-cart-ttle fs-3'>YOUR SHOPPING CART </span>
                                        <br />
                                        (<span className='crt-item-cnt'>{cartItems.length} item{cartItems.length > 1 ? 's' : ''} added in your cart</span>)
                                    </p>
                                </div>

                            </Offcanvas.Title>

                        </>

                    )}


                    <OffcanvasBody>
                        <Stack gap={3}>
                            {cartItems && cartItems.map(item => (
                                <CartItem key={item.id} {...item} />))
                            }
                            {cartItems && cartItems.length ? (<div>
                                <div className='me-auto fw-bold cart-ttl'>
                                    <p>Subtotal: ${calTotalPrice()} <br />
                                        Shipping: {state.isShippingFree ? (
                                            <span className="amount">Free Delivery</span>
                                        ) : (
                                            `$${state.shippingPrice}.00`
                                        )} <br />
                                        {/* HST%: ${calculateHST()}<br /> */}
                                        {/* Total: ${calculateFinalTotal()} */}
                                    </p>
                                </div>
                                {calTotalPrice() < 49 && (<div className='add-more-amount'><span >*</span>Add <b>${freeShippingRemainingAmount()}</b>  more to get Free Shipping</div>)}
                                <hr></hr>
                                <div className="cart-bottom align-items-center hstack gap-2">
                                    <Button className='me-auto p-3 cart-btn' onClick={goToCart} size="sm" style={{ background: "#7CC245", border: "2px solid #7CC245" }}>
                                        VIEW CART
                                    </Button>
                                    <Button onClick={goToCheckout} className='ms-auto fw-bold p-3 check-btn' size="sm" style={{ background: "#333", border: "2px solid #333" }}>CHECKOUT</Button>

                                </div>
                            </div>) : <div className="text-center">
                                <h2>No product added</h2>
                                Click<span className='lnk-cls' onClick={goToShopPage}> here to shop</span>
                            </div>}
                        </Stack>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </>

    )
}

export default MiniCartContainer;