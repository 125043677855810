import React from 'react'
import CommonDataProvider from '../../Context/CommonDataContext'
import { OrderProvider } from '../../Context/OrderProvider'
import CheckoutContainer from './CheckoutContainer'

const CheckoutIndex = () => {
    return (
        <OrderProvider>
            <CommonDataProvider>
                <CheckoutContainer></CheckoutContainer>
            </CommonDataProvider>

        </OrderProvider>

    )
}

export default CheckoutIndex