import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const TermsConditionsPage = () => {
    return <>
        <Helmet>
            <title>Terms and Conditions | Ayusage Private Limited - Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Checkout our terms and conditions. " />
        </Helmet>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Terms and Conditions</h2>

                            <p>Welcome to www.ayusage.com!</p>
                            <p>
                                These terms and conditions outline the rules and regulations for the use of Ayusage’s Website, located at www.ayusage.com.
                                <br></br>
                                These terms and conditions (the "Terms") outline the rules and regulations governing the use of Ayusage’s Website, located at www.ayusage.com. By accessing this website, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Website.
                                <br></br>
                            </p>

                            <h3>Definitions</h3>
                            <p>"Client," "You," and "Your" refer to you, the user of this Website.
                                <br></br>
                                "Company," "We," "Our," and "Us" refer to Ayusage.
                                <br></br>
                                "Website" refers to www.ayusage.com.
                            </p>

                            <h3>Cookies</h3>
                            <p>We use cookies on Ayusage.com to enhance user experience. By using our Website, you consent to the use of cookies in accordance with our Privacy Policy. You can manage your cookie preferences through your browser settings.</p>
                            <h3>License</h3>
                            <p>Unless otherwise stated, Ayusage and its licensors own the intellectual property rights for all material on ayusage.com. All intellectual property rights are reserved. You may access this content for your personal use, subject to the following restrictions:</p>

                            <p>- You must not republish, sell, rent, sub-license, reproduce, duplicate, copy, or redistribute content from ayusage.com without our explicit permission.</p>
                            <p>- Violation of this license may result in legal action.</p>

                            <h3>User-Generated Content (Comments)</h3>
                            <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Ayusage does not endorse or review user-generated Comments before they appear on the website. Users are responsible for the content they post. We reserve the right to remove Comments that violate our Terms and Conditions.</p>

                            <h3>Hyperlinking to our Content</h3>
                            <p>The following organizations may link to our Website without prior written approval:</p>
                            <p>Government agencies. <br />
                                Search engines.<br />
                                News organizations.<br />
                                Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses.<br />
                                System-wide Accredited Businesses, except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups, which may not hyperlink to our Website.</p>

                            <p>These organizations may link to our home page, publications, or other Website information, provided that the link:</p>

                            <p>- Is not deceptive.</p>
                            <p>- Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services.</p>
                            <p>- Fits within the context of the linking party's site.</p>
                            <h3>We may consider and approve other link requests from the following types of organizations:</h3>

                            <p>- Commonly-known consumer and/or business information sources.</p>
                            <p>- Ayusage.com community sites.</p>
                            <p>- Associations or other groups representing charities.</p>
                            <p>- Online directory distributors.</p>
                            <p>- Internet portals.</p>
                            <p>- Accounting, law, and consulting firms.</p>
                            <p>- Educational institutions and trade associations.</p>
                            <p>We will approve link requests from these organizations if we decide that:</p>
                            <br></br>
                            <p>- The link would not make us look unfavorably to ourselves or to our accredited businesses.</p>
                            <p>- The organization does not have any negative records with us.</p>
                            <p>- The benefit to us from the visibility of the hyperlink compensates the absence of Ayusage.</p>
                            <p>- The link is in the context of general resource information.</p>


                            <p>These organizations may link to our home page as long as the link</p>
                            <p>- Is not deceptive.</p>
                            <p>- Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services.</p>
                            <p>- Fits within the context of the linking party's site.</p>
                            <b>No use of Ayusage's logo or other artwork will be allowed for linking absent a trademark license agreement.</b>

                            <h3>iFrames</h3>
                            <p>Without prior approval and written permission, you may not create frames around our Webpages that alter the visual presentation or appearance of our Website.</p>

                            <h3>Content Liability</h3>
                            <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that arise on your Website. No links should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.</p>

                            <h3>Reservation of Rights</h3>
                            <p>We reserve the right to request that you remove all links or any particular link to our Website. By linking to our Website, you agree to comply with our linking terms and conditions. We may amend these terms at any time. If you find any link on our Website that is offensive, you are free to contact us. We will consider requests to remove links, but we are not obligated to do so or to respond to you directly.</p>

                            <h3>Disclaimer</h3>
                            <p>To the maximum extent permitted by applicable law:</p>
                            <p>- We do not guarantee the accuracy or availability of information on this website.</p>
                            <p>- We exclude all representations, warranties, and conditions relating to our website and the use of this website.</p>
                            <p>- Nothing in this disclaimer will limit or exclude our or your liability for death or personal injury, fraud, or fraudulent misrepresentation.</p>
                            <p>- The limitations and prohibitions of liability set in this Section govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
                            <p>- As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                            <h3>Contact Information</h3>
                            <p>If you have any questions or concerns about these Terms and Conditions, please contact us info@ayusage.com or ayusage5@gmail.com </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default TermsConditionsPage;