import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ReturnPolicyPage = () => {
    return <>
        <Helmet>
            <title>Return & Refund Policy | Ayusage - Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Discover our Return Policy and find out we make returns hassle-free and ensure your satisfaction with every purchase." />
        </Helmet>

        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-sm-12">

                            <h2>Returns and Cancellations Policy:</h2>

                            <h3>What you need to do?</h3>
                            <strong>Step 1 - </strong>Raise a contact form with the Subject "Order Return" and write reason for the return request with the order number and detail description of order.<br></br>
                            <strong>Step 2 - </strong> After reviewing your return request, if find you eligible, then we will send you return label.<br></br>
                            <strong>Step 3 - </strong> You will receive the return label by email notification. You need to schedule the pickup to you address by calling the carrier or can self-drop off at the nearest carrier location.<br></br>
                            <strong>Step 4 - </strong> You must pack the order within original packaging with the original receipt.<br></br>
                            <strong>Step 5 - </strong>After your product(s) is received, we will verify it against the claim and initiate the replacement or refund accordingly.
                            <p></p>

                            <p>We want to ensure our customers are satisfied with their purchase from us. If for any reason at all you are not satisfied with your purchase, we can arrange a return within 10 days from the arrival date of your order.</p>

                            <h3>When can be Returns or Refund?</h3>
                            <ul>
                                <li>Expired Product Delivered</li>
                                <li>Wrong Product Delivered.</li>
                                <li>Damaged Product Delivered.</li>
                            </ul>
                            <p>If the product was received damaged or defective, it can be returned to Ayusage within 2 working days from the arrival date of the order.</p>
                            <p>Once the order refund has been placed, you need to send the query from the contact form or drop a email on info@ayusage.com with detail description.</p>
                            <p></p>
                            <h3>Under what conditions Return/Refund will not be accepted.</h3>
                            <ul>
                                <li>Used or opened products.</li>
                                <li>Original packaging is altered or missing.</li>
                                <li>The damaged or wrong product reported after 2 days of delivery.</li>
                                <li>Any items marked as non-returnable on the product page.</li>
                                <li>Final sale products.</li>
                                <li>Please note that we are unable to accept returns for products purchased through another retailer.</li>
                            </ul>
                            <p></p>

                            <h3>Can I cancel my order?</h3>
                            <p>You can cancel the order from the CANCEL button from order details page of My Account section or raise the <Link to="/contact">contact us</Link> form or email at ayusage5@gmail.com or info@ayusage.com<br></br>
                                <strong>Note:</strong> You are allowed to cancel the order within 24 hours of your order time. Once we ship the order, then you are not allowed to cancel the order.
                            </p>


                            <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.<br></br>
                                If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 7-10 business days.</p>

                            <h3>Late or missing refunds.</h3>
                            <p>If you haven't received a refund yet, first check your bank account again.<br></br>
                                Then contact your bank, it may take some time before your refund is officially posted.<br></br>
                                There is often some processing time before a refund is posted.<br></br>
                                If you've done all of this and you still have not received your refund yet, please raise the contact form.</p>
                            <h3>Need help?</h3>
                            <p>Contact us at <Link to="mailto:ayusage5@gmail.com">ayusage5@gmail.com</Link> for questions related to refunds and returns.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section >

    </>
}
export default ReturnPolicyPage;