import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';

import { ProductProvider } from './Context/ProductContext';
import { ShoppingCartProvider } from './Context/ShoppingCartContext';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from './Context/CartContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

const helmetContext = {};

root.render(
  // <React.StrictMode>
  <>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ProductProvider>
          <ShoppingCartProvider>
            <CartProvider>
              <Helmet>
                <title>Best Natural Ayurvedic herbal supplement </title>
                <link rel="canonical" href="https://www.ayusage.com/" />
              </Helmet>
              <App />
            </CartProvider>
          </ShoppingCartProvider>
        </ProductProvider>
      </BrowserRouter>
    </HelmetProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
