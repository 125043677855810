import { Tooltip } from '@mui/material';
import React, { useContext, useEffect } from 'react';

import ShoppingCartContext from "../../Context/ShoppingCartContext";

import './TopCartStyle.css';

const TopCartContainer = () => {
    let { openCart, cartQuantity } = useContext(ShoppingCartContext);

    useEffect(() => {
        // Add a CSS class to trigger the animation
        const cartNumberSpanTop = document.querySelector('.top-cart-number-section');
        const cartNumberSpan = document.querySelector('.top-cart-number');

        if (cartNumberSpanTop || cartNumberSpan) {
            cartNumberSpanTop.classList.add('blink');
            cartNumberSpan.classList.add('blink');

            // Remove the CSS class after the animation duration (e.g., 1 second)
            setTimeout(() => {
                cartNumberSpanTop.classList.remove('blink');
                cartNumberSpan.classList.remove('blink');
            }, 1000);
        }
    }, [cartQuantity]);

    return (
        <>
            <div id="top-cart" className="header-misc-icon d-sm-block">
                <Tooltip className='up-down-arrw' title={"Click to View Your cart"}>
                    <span className={`top-cart-number-section ${cartQuantity > 0 ? 'blink' : ''}`} onClick={openCart}

                    ><i className="icon-line-bag"></i>
                        <span className={`top-cart-number ${cartQuantity > 0 ? 'blink' : ''}`}>{cartQuantity}</span>
                    </span></Tooltip>
            </div>

        </>
    )
}

export default TopCartContainer