import { Box, FormControl, TextField } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import SideBarComponent from '../SideBar/SideBarComponent'

const ChangePassword = () => {
    return (
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div id="page-title" style={{ backgroundColor: "transparent" }} className="mb- 5 page-title-mini">
                        <h3>Hi Sandeep Sidhu</h3>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/user-account/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                        </ol>
                    </div>
                    <div className="row clearfix shadow main-accunt">
                        <SideBarComponent></SideBarComponent>
                        <div className="col-md-9 px-5 py-4">
                            <Box component="form" sx={{ '& .MuiTextField-root': { mb: 2, mx: 1 } }} autoComplete="off">
                                <h3>Change Password</h3>
                                <div className="">
                                    <FormControl className='col-md-7'>
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="Old Password"
                                            name="old_password"
                                            defaultValue={''}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-7'>
                                        {/* <InputLabel id="">Full Name</InputLabel> */}
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="New Password"
                                            name="new_password"
                                            defaultValue={''}
                                        />
                                    </FormControl>

                                    <FormControl className='col-md-7'>
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="Confirm Password"
                                            name="confirm_password"
                                            defaultValue={''}
                                        />
                                    </FormControl>


                                    <div className='col-md-12 mx-2'>
                                        <button className="button bg-dark text-white button-dark button-large m-0" >Update</button>
                                    </div>
                                </div>
                            </Box>
                            <div className="clear"></div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ChangePassword