import axios from 'axios';
import { BASE_URL } from '../constant';

export default axios.create({
    baseURL: BASE_URL
});
let accessToken = localStorage.getItem('accessToken');

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer_ ${accessToken}`
    }
});