import { logDOM } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import ValidationMessage from './ValidationMessage';

const Question = ({ question, onNext, onPrevious, showValidation, selectedAnswer }) => {
    const [selectedOption, setSelectedOption] = useState(selectedAnswer);
    // console.log('question ', question);
    useEffect(() => {
        setSelectedOption(selectedAnswer); // Update selected option when it changes
    }, [selectedAnswer]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    return (
        // <div>
        //     <h3>{question.text}</h3>
        //     <div>
        //         {question.options.map((option, index) => (
        //             <label key={index}>
        //                 <input
        //                     type="radio"
        //                     value={option}
        //                     checked={selectedOption === option}
        //                     onChange={() => handleOptionSelect(option)}
        //                 />
        //                 {option}
        //             </label>
        //         ))}
        //     </div>
        //     {showValidation && selectedOption === null && (
        //         <ValidationMessage message="Please select an option" />
        //     )}
        //     {onPrevious && <button onClick={() => onPrevious(selectedOption)}>Previous</button>}
        //     <button onClick={() => onNext(selectedOption)}>Next</button>
        // </div>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="sections row">

                        <div className="col-sm-12 col-lg-12">

                            <div className="main-type py-3">
                                <h2>What is Your Dosha?</h2>
                                <hr></hr>
                                <div className="questions">
                                    {question && <h4>{question?.question}</h4>}
                                    {question.options.map((option, index) => (
                                        <label key={index}>
                                            <input
                                                type="radio"
                                                value={option.value}
                                                checked={selectedOption === option.value}
                                                onChange={() => handleOptionSelect(option.value)}

                                            />
                                            <span className='m-1'>{option?.text}</span>
                                        </label>
                                    ))}
                                </div>
                                {showValidation && selectedOption === null && (
                                    <ValidationMessage message="Please select an option.." />
                                )}
                                <div className="btns">
                                    {/* {question && question.id != 1 && <button className="button prev" type="button" onClick={prevStep}>
                                            Previous
                                        </button>}
                                        <button className="button next" type="submit">Next</button> */}
                                    {onPrevious && <button className='button prev' onClick={() => onPrevious(selectedOption)}>Previous</button>}
                                    <button className="button next" onClick={() => onNext(selectedOption)}>Next</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Question;
