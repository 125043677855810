

import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, FormControl, TextField, Typography, Accordion, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useNavigate } from "react-router-dom";

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from '../../api/axios';
import { useCart } from '../../Context/CartContext';
import useScrollToTop from '../../hooks/useScrollToTop';
import Shimmer from '../../component/CommonComponent/Shimmer';
import { ProductContext } from '../../Context/ProductContext';


const CheckoutContainer = () => {
    useScrollToTop();
    let navigate = useNavigate();

    const [clientSecret, setClientSecret] = useState(true);
    const [backToCartPage, setBackToCartPage] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const { state, dispatch, calculateSubtotal, calculateTotal } = useCart();
    const { productList } = useContext(ProductContext);

    const [isBillingAddressSame, setIsBillingAddressSame] = useState(true);
    const [loadingPayButton, setLoadingPayButton] = useState(false);


    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [pinCode, setPinCode] = useState('');

    const handleSetFullName = (event) => {
        setFullName(event.target.value);
    }
    const handleSetEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleSetPhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    }
    const handleSetAddress = (event) => {
        setAddress(event.target.value);
    }
    const handleSetPinCode = (event) => {
        setPinCode(event.target.value);
    }

    //Razor pay integration: ....

    const checkoutHandler = async () => {
        setLoadingPayButton(true);
        const { data: { key } } = await axios.post('/api/v1/payment/razorPayKey');
        const { data: { order } } = await axios.post('/api/v1/payment/checkout', {
            amount: calculateFinalTotal()
        });

        const options = {
            key,
            amount: order.amount,
            currency: "CAD",
            name: "Ayusage Pvt. Ltd.",
            description: "Ayusage Private Limited",
            image: "https://ayusage-public.s3.us-east-2.amazonaws.com/ayusage-logo.png",
            order_id: order.id,
            callback_url: process.env.REACT_APP_LOCAL_API_URL + '/api/v1/payment/razorpayVerification',
            prefill: {
                name: fullName,
                email: "",
                contact: ""
            },
            notes: {
                address: address
            },
            theme: {
                color: "#7cc144"
            }
        };
        const razor = new window.Razorpay(options);
        razor.open();
        setLoadingPayButton(false);
    }

    const handleChangeIsBillingAddressSame = (event) => {

        console.log('event.target.checked: ', event.target.checked);

        setIsBillingAddressSame(event.target.checked);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const goToCart = () => {
        navigate('/cart');
    }
    const appearance = {
        theme: 'stripe',
    };

    const [userEmail, setUserEmail] = React.useState('');

    // Callback function to handle username change
    const handleUserEmailChange = (newUserEmail) => {
        // console.log('newUserEmail: ', newUserEmail);
        setUserEmail(newUserEmail);
    };

    useEffect(() => {
        const matchedProducts = productList
            .filter(product => state.cartItems.some(item => item.id === product.id))
            .map(({ id, name, price }) => ({ id, name, price }));

        console.log('matchedProducts: ', matchedProducts);

    }, []);
    const options = {
        clientSecret,
        appearance,
    };

    const calculateHST = () => {
        // Calculate HST based on the final subtotal
        return ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
    };

    const calculateFinalTotal = () => {
        let calHst = ((parseFloat(state.finalSubtotal) * state.totalHST) / 100).toFixed(2);
        let shiping = state.isShippingFree ? 0.00 : state.shippingPrice;
        // Calculate HST based on the final subtotal
        return ((parseFloat(state.finalSubtotal) + parseFloat(calHst)) + parseFloat(shiping)).toFixed(2);
    };
    const freeShippingRemainingAmount = () => {
        const remainingAmount = 49 - state.finalSubtotal;
        // const roundedAmount = Math.ceil(remainingAmount);
        return remainingAmount.toFixed(2);
    }
    return <>
        <div className="container">
            {!clientSecret && !backToCartPage && (<Shimmer />
            )}

            {backToCartPage && <div className="p-5 text-center"> Something Went wrong. Please go back to <a href="/cart"> cart page </a> </div>}
            {clientSecret && (<Box sx={{ width: '100%', marginTop: '5%' }}>
                <div className='row'>
                    <div className="col-md-7">
                        {/* <p className='m-3'><h4>Billing Address:</h4></p> */}


                        <Accordion defaultExpanded className='shipping-address'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className='m-3'>
                                    Shipping Address:
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='col-md-8 m-3'>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="full_name"
                                        label="Full Name"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleSetFullName}
                                    />
                                </div>
                                <div className='col-md-8 m-3'>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="full_name"
                                        label="Email address"
                                        type="email"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleSetEmail}
                                    />
                                </div>
                                <div className='col-md-8 m-3'>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="full_name"
                                        label="Phone Number"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleSetPhoneNumber}
                                    />
                                </div>

                                <div className='col-md-8 m-3'>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="address"
                                        name="text"
                                        label="Address"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleSetAddress}
                                    />
                                </div>
                                <div className='col-md-8 m-3'>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="pin_code"
                                        label="Pin Code"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleSetPinCode}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={isBillingAddressSame} onChange={handleChangeIsBillingAddressSame} name="isBillingAddressSame" />
                                }
                                label="Billing address same as shipping address"
                            />
                        </FormGroup>
                        {!isBillingAddressSame && (<Accordion className='shipping-address'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>Billing Address</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AccordionDetails>
                                    <div className='col-md-8 m-3'>
                                        <TextField
                                            autoFocus
                                            required
                                            margin="dense"
                                            id="name"
                                            name="full_name"
                                            label="Full Name"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                        // onChange={handleSetReferalEmail}
                                        />
                                    </div>
                                    <div className='col-md-6 m-3'>
                                        <TextField
                                            autoFocus
                                            required
                                            margin="dense"
                                            id="name"
                                            name="full_name"
                                            label="Email address"
                                            type="email"
                                            fullWidth
                                            variant="outlined"
                                        // onChange={handleSetReferalEmail}
                                        />
                                    </div>
                                    <div className='col-md-6 m-3'>
                                        <TextField
                                            autoFocus
                                            required
                                            margin="dense"
                                            id="name"
                                            name="full_name"
                                            label="Phone Number"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                        // onChange={handleSetReferalEmail}
                                        />
                                    </div>
                                    <div className='col-md-6 m-3'>
                                        <TextField
                                            autoFocus
                                            required
                                            margin="dense"
                                            id="address"
                                            name="text"
                                            label="Address"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                        // onChange={handleSetReferalEmail}
                                        />
                                    </div>
                                </AccordionDetails>
                            </AccordionDetails>
                        </Accordion>)}


                    </div>
                    <div className="col-md-5 shadow p-4 cart-right-panel">
                        <table className="table cart cart-totals  crt-itms">
                            <tbody>
                                <tr className="cart_item">
                                    <td className="cart-product-name">
                                        <strong>Cart Subtotal</strong>
                                    </td>
                                    <td className="cart-product-result">
                                        {/* ${subTotalPrice} */}
                                        ${state.finalSubtotal}
                                    </td>
                                </tr>
                                <tr className="cart_item">
                                    <td className="cart-product-name">
                                        <strong>Shipping</strong>
                                    </td>

                                    <td className="cart-product-result">
                                        {/* {isShippingFree ? <span className="amount">Free Delivery</span> : '$' + shippingPrice + '.00'} */}
                                        {state.isShippingFree ? (
                                            <span className="amount">Free Delivery</span>
                                        ) : (
                                            `$${state.shippingPrice}.00`
                                        )}
                                    </td>
                                </tr>
                                <tr className="cart_item">
                                    <td className="cart-product-name">
                                        <strong>HST %</strong>
                                    </td>

                                    <td className="cart-product-result">
                                        {/* ${(parseFloat(subTotalPrice) * totalHST / 100).toFixed(2)} */}
                                        ${calculateHST()}
                                    </td>
                                </tr>
                                <tr className="cart_item">
                                    <td className="cart-product-name">
                                        <strong>Total</strong>
                                    </td>

                                    <td className="cart-product-result">
                                        <span className="amount color lead">
                                            ${calculateFinalTotal()}
                                            {/* ${finalTotalPrice(subTotalPrice, totalHST, shippingPrice)} */}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {state.finalSubtotal < 49 && (<div className='add-more-amount'><span >*</span>Add <b>${freeShippingRemainingAmount()}</b>  more to get Free Shipping. &nbsp; <a href='/shop'>Continue Shopping</a></div>)}
                        <br />
                        <div className="strip-checkout">
                            <div>
                                {loadingPayButton ? <button style={{ cursor: 'pointer' }} className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4">{loadingPayButton ? <span>Loading...</span> : ''}</button> :
                                    <button style={{ cursor: 'pointer' }} className="button button-rounded ls0 fw-semibold ms-0 mb-2 nott px-4" onClick={() => checkoutHandler()} >Pay Securely </button>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <MainCartIndex type={'final_checkout_page'} ></MainCartIndex> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2, my: 2 }}>
                    {activeStep !== 0 && (<Button
                        color="inherit"
                        variant="outlined"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        startIcon={<KeyboardBackspaceIcon />}
                    >Back</Button>)}
                    <Box />
                </Box>

            </Box>)}
        </div>
    </>
}
export default CheckoutContainer;