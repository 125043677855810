import { BrowserRouter, Routes, Route, NavLink, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import PaymentIcon from '@mui/icons-material/Payment';
import ShieldIcon from '@mui/icons-material/Shield';
import MailchimpForm from '../component/Mailchimp';
import CookieConsentBanner from './CookieConsentBanner';

const FooterPage = () => {
    return (
        <>
            {/* <!-- Footer
============================================= --> */}
            <footer id="footer" className="bg-transparent border-1">
                <div className="footer-main container">

                    <div className="section footer-stick bg-white m-0 py-3 border-bottom">
                        <div className="container clearfix">

                            <div className="row clearfix foter-icons">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="feature-box fbox-center fbox-light fbox-effect border-bottom-0">
                                        <div className="fbox-icon">
                                            <a href="/shop"><i className="i-alt border-0 icon-truck"></i></a>
                                        </div>
                                        <div className="fbox-content">
                                            <h4 className="inline-block mb-0 ms-2"><a href="/shop">FREE SHIPPING</a></h4><br />
                                            <span className="fw-normal text-muted">on orders above $49 </span>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-4">
                                    <div className="feature-box fbox-center fbox-light fbox-effect border-bottom-0">
                                        <div className="fbox-icon">
                                            <a href={'/consultation'}><i className="i-alt border-0 icon-user"></i></a>
                                        </div>
                                        <div className="fbox-content">
                                            <h4 className="inline-block mb-0 ms-2">
                                                <a href={'/consultation'}>FREE DOCTOR CONSULTATION</a>
                                            </h4>
                                            <br />
                                            <span className="fw-normal text-muted"><a href={'/consultation'}>Book An Appointment</a> </span>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-4">
                                    <div className="feature-box fbox-center fbox-light fbox-effect border-bottom-0">
                                        <div className="fbox-icon">
                                            <a href="/contact">
                                                <i className="i-alt border-0 icon-question"></i>
                                            </a>
                                        </div>
                                        <div className="fbox-content">
                                            <h4 className="inline-block mb-0 ms-2">
                                                <a href="/contact">HAVE QUERIES OR CONCERNS</a>
                                            </h4>
                                            <br />
                                            <span className="fw-normal text-muted"><a href="/contact">Contact for your doubts or issues </a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="foter-second-row">
                                <div className="row clearfix">
                                    <div className="col-sm-6 col-lg-6">
                                        <div className="fbox-content">
                                            <h4 className="inline-block mb-2 greenclr">PAYMENT</h4>
                                            <br />
                                            <ShieldIcon /> <span className="fw-normal text-muted"> 100% Payment Protection, Easy Cancellation Policy</span>
                                            <br />

                                            {/* <PaymentIcon /> <img alt='stripe-card-icon' src='/icons/stripe-card-icon.png' /> */}

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <div className="fbox-content">
                                            <h4 className="inline-block mb-3 ms-2 greenclr">AYUSAGE IS AUTHENTIC, NATURAL &
                                                CERTIFIED
                                                COMPANY!
                                            </h4>
                                            <br />
                                            <img src={require("../images/ISO9000.png")} width="85" />
                                            <img src={require("../images/ISO22000.png")} width="85" />
                                            <img src={require("../images/Organic.png")} width="85" />
                                            <img src={require("../images/HACCP-certified.png")} width="85" />
                                            <img src={require("../images/GMP-certified.png")} width="85" />
                                            <img src={require("../images/third-party-certificate.png")} width="85" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container clearfix">

                        {/* <!-- Footer Widgets
    ============================================= --> */}
                        <div className="footer-widgets-wrap pb-3 border-bottom clearfix">

                            <div className="row">

                                <div className="col-lg-3 col-md-3 col-6">
                                    <div className="widget clearfix">
                                        <h4 className="ls0 mb-3 nott">USEFUL LINKS</h4>

                                        <ul className="list-unstyled iconlist ms-1">
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/about">About</a></li>
                                            <li><a href="/shop">Shop</a></li>
                                            <li><a href="/bodytype">Know you body type</a></li>
                                            <li><a href="/consultation">Book Consultation</a></li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-6">
                                    <div className="widget clearfix">

                                        <h4 className="ls0 mb-3 nott">Top Rated Products</h4>

                                        <ul className="list-unstyled iconlist ms-1">
                                            <li><a href={'/product/imunisage'}>Imunisage</a></li>
                                            <li><a href={'/product/hairsage'}>Hairsage</a></li>
                                            <li><a href={'/product/moringa'}>Moringa</a></li>
                                            <li><a href={'/product/tribulus'}>Triblus</a></li>
                                            <li><a href={'/product/turmeric'}>Turmeric</a></li>
                                        </ul>

                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-6">
                                    <div className="widget clearfix">
                                        <h4 className="ls0 mb-3 nott">Shopping Guide</h4>
                                        <ul className="list-unstyled iconlist ms-1">
                                            <li><a href="/contact">Contact Us</a></li>
                                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                                            <li><a href="/shipping-delivery">Shipping and Delivery Policy</a></li>
                                            <li><a href="/return-policy">Refund and Cancellation Policy</a></li>
                                            <li><a href="/terms-conditions">Terms and Conditions</a></li>

                                            <li><a href="/faq">FAQs</a></li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-8">
                                    <div className="widget clearfix">

                                        <h4 className="ls0 mb-3 nott">Subscribe Now</h4>
                                        <div className="widget subscribe-widget mt-2 clearfix">
                                            <p className="mb-2"><strong>Subscribe</strong> to Our Newsletter to get Important News, Amazing Offers &amp; Inside Scoops:</p>
                                            <MailchimpForm></MailchimpForm>
                                        </div>



                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <CookieConsentBanner />
                    {/* Copytight */}
                    <div id="copyrights" className="bg-transparent">

                        <div className="container clearfix">

                            <div className="row justify-content-between align-items-center">
                                <div className="col-md-6">
                                    © Copyright 2024 Ayusage® Private Limited. All Rights Reserved.<br />

                                </div>

                                <div className="col-md-6 d-md-flex flex-md-column align-items-md-end mt-4 mt-md-0">
                                    <div className="copyrights-menu copyright-links clearfix">
                                        <span className='mr-3'>Designed and Develop by <a style={{ color: '#8AC541' }} href='https://webinventive.ca'>Web Inventive Inc.</a></span>
                                        <a href="/terms-conditions">Terms and Conditions</a>/<a href="/faq">FAQs</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>   {/*  <!-- #copyrights end --> */}
                </div>
                {/* <!-- footer-main--> */}

            </footer>
            {/* <!-- #footer end --> */}

        </>
    )
}

export default FooterPage;