import { Link } from 'react-router-dom';
import useScrollToTop from '../../hooks/useScrollToTop';
import './Index.css'

const ErrorPage = () => {
  useScrollToTop()
  return <>
    <div className="error-container">
      <h2>404 - Oops! Looks like this page is taking a wellness break.</h2>
      <p className='p-nt-fnd'>Uh-oh! It appears you've ventured off the healthy path while navigating our nature supplement store.".</p>
      {/* <img src="path-to-your-illustration.png" alt="Lost Astronaut Illustration" className="illustration" /> */}
      <p className="emoji">👨‍🚀</p>
      <p className='p-nt-fnd'>Fear not, brave explorer! We've set a course to get you back:</p>
      <Link to="/" className="home-link">Beam Me Home</Link>
    </div>
  </>
}
export default ErrorPage;