import { Navigate, Outlet, useLocation } from "react-router-dom"
import MainNavigation from "../component/MainNavigation";
import FooterPage from "./Footer"
import HeaderIndex from "../component/Header/HeaderIndex"

import useAuth from "../hooks/useAuth";
import { ShoppingCartProvider } from "../Context/ShoppingCartContext";

const RootLayout = () => {
    const { auth } = useAuth();
    const location = useLocation();
    return <>
        <div className="large-container">
            {/* <ShoppingCartProvider> */}
            <HeaderIndex />
            {/* {auth?.user
                ? <Outlet />
                : <Navigate to="/login" state={{ from: location }} replace />
            } */}
            <Outlet />
            <FooterPage />
            {/* </ShoppingCartProvider> */}
        </div>
    </>
}

export default RootLayout;