import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useScrollToTop from '../hooks/useScrollToTop';

const DoshaPage = () => {
    useScrollToTop();
    return <>
        <Helmet>
            <title>What is Dosha - Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Know your Body Type from Kapha | Pitta | Vatta. DISCOVER YOUR DOSHA with our simple questionnaire realted to youir body type." />
        </Helmet>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="sections row">
                        <div className="col-sm-6 col-lg-6">
                            <h2>What is Ayurveda Body Type?</h2>
                            Ayurveda explains that everything in this universe is outcome of combination of five elements in different permutation and combinations. So does the panchbmahabhuta forms the basic foundation of human body and everyone is different from each other. Five elements are expressed in our body in the form of three bio energies called Three Doshas or Humors.These doshas are fixed during the time of conception which decide our Prakruti called Ayurveda Body type, it never changes and also understood as Ayurgenetics of each human body. So Knowing or decoding our basic body type called prakruti helps us a lot in understanding, what type of diet, lifestyle is best suited for us. It further helps us to understand how our body interact with change of seasons, what herbs are best designed by nature for different individuals, knowing Prakruti also helps in predicting the probability of disorders we can have in future and how to balance our body with natural ways.
                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <img src={require("../images/wellness.jpeg")} alt="Know your Dosha" />
                        </div>
                    </div>

                    <div className="sections py-6">
                        <div className="row">
                            <h5 style={{ color: "#7cc245" }}>Vatta | Pitta | Kapha</h5>
                            <h3>What are THREE DOSHAS?</h3>
                            <div className="col-sm-12 col-lg-12">
                                Doshas are three biophysical forms or energies which govern all biological, physiological and psychological functions of mind and body. Ayurveda explains that five elements combined in different ratios lead to the formations of doshas. Hence Doshas are the reasons how individuals interact with its environment. Doshas are basic pillars of the human body. Balanced state of Doshas is responsible for the healthy state of individuals and unbalanced state of doshas leads to disease in our body.
                            </div>

                        </div>
                    </div>

                    {/* <!-- Dosha Types
          ============================================= --> */}
                    <div className="sections">
                        <div className="row bottommargin-lg align-items-stretch">

                            <div className="col-lg-4 dark col-padding overflow-hidden" style={{ "backgroundColor": "#34495e" }}>
                                <div>
                                    <h3 className="text-uppercase" style={{ "fontWeight": "600" }}>VATTA</h3>
                                    <p style={{ "lineHeight": "1.8" }}>Vata doshas is an expression of integration of Space and Air elements. It expresses itself in the body with dry, light, cold, subtle & rough properties. It is called the energy of movement as it is responsible for all the movements in the body like respiration, pulsation, perception, initiation of natural urges, movements.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 dark col-padding overflow-hidden" style={{ "backgroundColor": "#c8a383" }}>
                                <div>
                                    <h3 className="text-uppercase" style={{ "fontWeight": "600" }}>PITTA</h3>
                                    <p style={{ "lineHeight": "1.8" }}>Pitta doshas is an expression of integration of fire and water elements. It expresses itself in the body with hot, light, sharp, slightly oily and movable properties. It is called the energy of transformation as it is responsible for digestion, metabolism, temperature regulation, colour, complexion, courage, desire and intelligence.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 dark col-padding overflow-hidden" style={{ "backgroundColor": "#f4f4f4", "color": "#000000" }}>
                                <div>
                                    <h3 className="text-uppercase" style={{ "fontWeight": "600", "color": "#000000" }}>
                                        KAPHA</h3>
                                    <p style={{ "lineHeight": "1.8" }}>Kapha doshas is an expression of integration of water and earth elements. It expresses itself in the body with heavy, dull, unctuous, slow, cold, smooth and stable properties. It is called the energy of grounding or preservation as it is responsible for stability, strength, forbearance, joint integrity and proper immune response.
                                    </p>
                                </div>
                            </div>

                            <div className="clear"></div>

                        </div>
                    </div>
                    {/* <!-- Dosha Types End
          ============================================= --> */}

                    <div className="sections row">
                        <div className="col-sm-6 col-lg-6">
                            <img src={require("../images/know-your-dosha.jpeg")} alt="Know your Dosha" />
                        </div>
                        <div className="col-sm-6 col-lg-6 py-4">
                            <h3>What makes our dosha type questionnaire unique?</h3>
                            <h5>DISCOVER YOUR DOSHA (Ayurvedic Mind-Body Type) </h5>
                            <p>Taking 5000 years old ancient vedic literature as the basis, we have designed this Ayurveda body type questionnaire in such a way that we can have meticulous and in depth evaluation of your Ayurveda Body type.
                            </p>
                            <Link to="/bodytype" className="button button-rounded text-uppercase m-0">Know Your Dosha Free</Link>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    </>
}
export default DoshaPage;