import React from 'react';
import { Box, IconButton, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import SideBarComponent from '../SideBar/SideBarComponent';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #d5d5d5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

const OrderlList = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div id="page-title" style={{ backgroundColor: "transparent" }} className="mb- 5 page-title-mini">
                            <h3>Hi Sandeep Sidhu</h3>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/user-account/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">My Orders</li>
                            </ol>
                        </div>
                        <div className="row clearfix shadow main-accunt">
                            <SideBarComponent></SideBarComponent>
                            <div className="col-md-9 px-5 py-4">
                                <h3>My Orders</h3>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Order ID</StyledTableCell>
                                                <StyledTableCell align="right">Item Name</StyledTableCell>
                                                <StyledTableCell align="right">Quantity</StyledTableCell>
                                                <StyledTableCell align="right">Price</StyledTableCell>
                                                <StyledTableCell align="right">Date</StyledTableCell>
                                                <StyledTableCell align="right">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">Item 2</StyledTableCell>
                                                <StyledTableCell align="right">1</StyledTableCell>
                                                <StyledTableCell align="right">2</StyledTableCell>
                                                <StyledTableCell align="right">2</StyledTableCell>
                                                <StyledTableCell align="right">August 21, 2023</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <span style={{ cursor: 'pointer' }} onClick={handleOpen}>View</span>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="clear"></div>
                            </div>
                            <div className="w-100 line d-block d-md-none"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={open ? 'modal-open' : 'modal-closed'}
            >
                <Box sx={style}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="Close">
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '8px',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Order ID: #10023
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className='shadow p-4 br-5'>
                            <div>Product Name: Imunisage</div>
                            <div> Image: <img alt={''} src={''} /></div>
                            <div> Price: $15.99</div>
                            <div> Address: Brampton, Ontario, Canada</div>
                        </div>
                        <div className='shadow p-4 br-5'>
                            <div>Product Name: Hairsage</div>
                            <div> Image: <img alt={''} src={''} /></div>
                            <div> Price: $15.99</div>
                            <div> Address: Brampton, Ontario, Canada</div>
                        </div>
                        <div className='shadow p-4 br-5'>
                            <div>Product Name: Khaphsage</div>
                            <div> Image: <img alt={''} src={''} /></div>
                            <div> Price: $15.99</div>
                            <div> Address: Brampton, Ontario, Canada</div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>

    )
}

export default OrderlList