import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

const Orderplaced = () => {
    let navigate = useNavigate();

    const seachQuery = useSearchParams()[0];
    const referenceNum = seachQuery.get("reference")

    localStorage.clear('cartItems');
    // useEffect(() => {
    //     navigate('/thank-you');
    // }, [])
    return (
        <div className='text-center mt-5'>
            <h2>Thank you, Your order has been place.</h2>
            <p>Your payment reference number is <b>{referenceNum}</b> .</p>
            <p>You will be get notified by email.</p>
            <p>Click<a href='/shop' alt={'shop again'} > here to shop</a></p>

        </div>
    )
}

export default Orderplaced