import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import './BodyType.css';
import useScrollToTop from '../../hooks/useScrollToTop';
import { Alert, FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../utils/common.utils';
import axios from '../../api/axios';
import CloseIcon from '@mui/icons-material/Close';
import QuizComponent from './QuizComponent';
import { Helmet } from 'react-helmet';

function Step({ question, options, formData, setFormData, emptyErrorMessage, prevStep, nextStep }) {
    const [isQuizStart, setIsQuizStart] = useState(false);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        console.log('Selected Val', value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('event value:', event.target.value);
        nextStep();
    };
    const handleClose = () => setIsQuizStart(false);

    return (
        <>
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div className="sections row">

                            <div className="col-sm-12 col-lg-12">

                                <div className="main-type shadow mb-5">

                                    <form className="bodytype" onSubmit={handleSubmit}>
                                        <h2>Know Your Body Type</h2>
                                        <hr></hr> <Tooltip title="Close">
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleClose}
                                                sx={{
                                                    position: 'absolute',
                                                    right: '8px',
                                                    top: '8px',
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <div className="questions">
                                            {emptyErrorMessage && (<p className='alert alert-danger'>{emptyErrorMessage}</p>)}
                                            {question && <h4>{question.question}</h4>}
                                            {options && options.map((option) => (
                                                <label key={option.id}>
                                                    <input
                                                        type="radio"
                                                        name={`value`}
                                                        value={`${option.value}`}
                                                        onChange={handleChange}
                                                    />
                                                    <span>{option.text}</span>
                                                </label>
                                            ))}
                                        </div>
                                        <div className="btns">
                                            {question && question.id != 1 && <button className="button prev" type="button" onClick={prevStep}>
                                                Previous
                                            </button>}
                                            <button className="button next" type="submit">Next</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>

    );
}

function BodyTypePage() {
    // useScrollToTop();
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [submitting, setSubmitting] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [email, setEmail] = useState('');
    const [isQuizStart, setIsQuizStart] = useState(false);
    const [emptyErrorMessage, setEmptyErrorMessage] = useState('');

    const handlingEmail = (event) => {
        let email = event.target.value;
        if (validateEmail(email.trim())) {
            setIsEmailInvalid(false);
        } else if (!validateEmail(email.trim())) {
            setIsEmailInvalid(true);
        }
        if (email.trim() === '') {
            setIsEmailEmpty(true);
        } else {
            setIsEmailEmpty(false);
        }
        setEmail(email);
    }

    const getBodyTypeQuestionsAns = useCallback(async () => {
        let response = await axios.get('/api/v1/getBodyTypeQA');
        setQuestions(response?.data?.data);
    }, [])


    useEffect(() => {
        getBodyTypeQuestionsAns();
    }, []);

    const nextStep = () => {
        setCurrentStep((prevState) => prevState + 1);
    };
    const prevStep = () => {
        setCurrentStep((prevState) => prevState - 1);
    };

    let bodyData = [];
    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            console.log(bodyData, 'test', formData);
            if (JSON.stringify(formData) === '{}') {
                console.log('Please enter required fields.');
                setEmptyErrorMessage('Please choose one option');
                return false;
            } else {
                bodyData.push({ value: formData.value });
                localStorage.setItem('bodytTypeData', bodyData);
                setCurrentStep((prevState) => prevState + 1);
                setFormData({});

            }
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false);
    };
    console.log('formData from child: ', formData);
    const renderStep = () => {
        const question = questions[currentStep - 1];
        return (
            <Step
                question={question}
                options={question && question.options}
                formData={formData}
                setFormData={setFormData}
                emptyErrorMessage={emptyErrorMessage}
                prevStep={prevStep}
                nextStep={handleSubmit}
            />
        );
    };

    const myDivRef = useRef(null);

    // Function to scroll to the div
    const scrollToDiv = () => {
        if (myDivRef.current) {
            myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleStartQuiz = (type) => {
        if (email !== '' && isEmailInvalid === false && isEmailEmpty === false) {
            setIsQuizStart(true);
            if (type === "top") {
                scrollToDiv();
            }

        }
        else {
            setIsEmailEmpty(true);
        }
    }

    return (
        <div>
            <Helmet>
                <title>Know Your Dosha - Natural A  yurvedic Supplement Store</title>
                <meta name="description" content="Have in depth evaluation of your Ayurveda Body type by our dosha Ayurveda questionnaire for free in simple steps." />
            </Helmet>
            <section id="page-title" className='page-title-body-type' style={{ "background": "url('./body_type.jpg')" }}>
                <div className="container">
                    <h1>Know Your Body Type</h1>
                    <span className='mb-4' >with simple Dosha Quiz</span>
                    <FormControl className='col-md-3'>
                        <TextField
                            error={isEmailEmpty || isEmailInvalid}
                            helperText={isEmailInvalid ? "Enter  Valid Email" : ''}
                            id="outlined-basic outlined-error"
                            label="Your Email Address"
                            name="email"
                            defaultValue={''}
                            onChange={handlingEmail}
                        />
                    </FormControl>
                    <button onClick={() => handleStartQuiz("top")} className="button bg-dark text-white button-dark button-medium m-0">START NOW</button>
                </div>
            </section>
            <section id="content" className='mb-5'>
                <div className="content-wraps">
                    <div className="container clearfix">
                        <div className='text-center my-5'>
                            <h2 className="card-title mb-4 ls0">Discover Your Body Type
                            </h2>
                            <p>Doshas represent three biophysical energies that govern all biological, physiological, and psychological functions of both the mind and body. According to Ayurvedic principles, these doshas arise from the combination of five elements in varying proportions. Maintaining a harmonious balance of doshas is crucial for overall well-being, as an imbalance in these energies can result in bodily ailments and disturbances. Know your body type by anwering the simple questions and get free consultation from doctor.</p>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-4">
                                <div className='icon-nmbr'>
                                    <span>1</span>
                                </div>
                                <div className="feature-box fbox-sm fbox-plain">
                                    <div className="fbox-icon">
                                        <i className="icon-line2-question text-dark text-dark"></i>
                                    </div>
                                    <div className="fbox-content">
                                        <h3 className="fw-normal"><strong>Take the Short Questionnaire</strong></h3>
                                        <p>Start the quiz now and unlock the secrets to a harmonious and healthier lifestyle!</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 mt-4 mt-sm-0">
                                <div className='icon-nmbr'>
                                    <span>2</span>
                                </div>
                                <div className="feature-box fbox-sm fbox-plain">

                                    <div className="fbox-icon">
                                        <i className="icon-line2-user text-dark"></i>
                                    </div>
                                    <div className="fbox-content">
                                        <h3 className="fw-normal"><strong>Free Consultation with Specialist</strong></h3>
                                        <p>30 minute free video consultation with an Ayurvedic Expert Doctor </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4 mt-sm-0">
                                <div className='icon-nmbr'>
                                    <span>3</span>
                                </div>
                                <div className="feature-box fbox-sm fbox-plain">
                                    <div className="fbox-icon">
                                        <i className="icon-line2-basket text-dark"></i>
                                    </div>
                                    <div className="fbox-content">
                                        <h3 className="fw-normal"><strong>Shop Ayurvedic Supplements</strong></h3>
                                        <p>100% organic supplemts that will nourish you from inside.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {!isQuizStart && (<section ref={myDivRef} id="quiz" className="content-wrap text-center">
                <div className="container clearfix">
                    <hr></hr>
                    <h2 className='py-4'>Are you prepared to uncover your true self?</h2>
                    <FormControl className='col-md-4'>
                        <TextField
                            error={isEmailEmpty || isEmailInvalid}
                            helperText={isEmailInvalid ? "Enter  Valid Email" : ''}
                            id="outlined-basic outlined-error"
                            label="Your Email Address"
                            name="email"
                            defaultValue={''}
                            onChange={handlingEmail}
                        />
                    </FormControl>
                    <button onClick={handleStartQuiz} className="button bg-dark text-white button-dark button-medium m-0">START NOW</button>
                </div>
            </section>)}
            {/* {JSON.stringify(questions)} */}
            {isQuizStart && questions && questions.length > 0 && (
                currentStep <= questions.length ? (
                    // renderStep()

                    <QuizComponent questions={questions} enteredEmail={email} />
                ) : (
                    <section id="content">
                        <div className="content-wrap">
                            <div className="container clearfix">
                                <div className="sections row">
                                    <div className="col-sm-12 col-lg-12"></div>
                                    <div className="main-type shadow text-center py-5">

                                        <h2>Thank you for your submission!</h2>
                                        <span>Shortly you will recieve the results in email.</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            )}
            <section className="page-title-parallax page-title-light paralx" style={{ "background": "url('./paralax.jpeg')", "height": "600px" }}>
                <div className="container clearfix">
                    <h2>Discover Your <br></br>
                        Body Types
                    </h2>
                </div>
            </section>
        </div>
    );
}
export default BodyTypePage;
