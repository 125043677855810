import { createContext, useEffect, useReducer } from 'react';
import axios from '../../src/api/axios';
import { axiosPrivate } from '../../src/api/privateAxios';
import Axios from 'axios';
// import { api } from 'src/config';
// import crypto from 'crypto-browserify';
// import jwt from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';
// import { JWT_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
const JWT_SECRET = 'd7cbaa2537d4cf9cf2a079e79b0e166e6e7f0bfd006e8e1bd05429891731f6919c01c83740abfe56922e54ee1fa0182b0962b10e4a7a82e601331fa64b43bac0';
const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};
const setDefaultApi = () => {
    Axios.defaults.baseURL = process.env.REACT_APP_LOCAL_API_URL;
};

// This function decodes the JWT token
const decodeToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken;
    } catch (error) {
        console.error('Invalid token:', error);
        return null;
    }
};

const setSession = (accessToken) => {
    setDefaultApi();

    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        Axios.defaults.headers.common['authorization'] = `${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete Axios.defaults.headers.common['authorization'];
    }
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    }
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    loginToken: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    console.log('Auth Provider.');
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                console.log('~ accessToken', accessToken);
                if (accessToken) {
                    setSession(accessToken);
                    const response = await Axios.post('/api/v1/user/dashboard');
                    console.log('IN jwt auth context initialize: ', response);
                    const { user } = response?.data?.data;
                    console.log('agagin in init', user);
                    if (user) {
                        console.log('I am in if');
                        dispatch({
                            type: 'INITIALIZE',
                            payload: {
                                isAuthenticated: true,
                                accessToken,
                                user
                            }
                        });
                    } else {
                        console.log('else I am in if');
                        dispatch({
                            type: 'INITIALIZE',
                            payload: {
                                isAuthenticated: false,
                                user: null
                            }
                        });
                    }
                } else {
                    console.log('else I am in if');
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }

            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialize();
    }, []);

    const login = async (email, password) => {
        console.log('I am here in login..');
        setDefaultApi();
        const response = await Axios.post('/api/v1/auth/login', {
            email,
            password
        });

        console.log('Login Token=>', response);

        const { accessToken, user } = response.data;
        setSession(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
                isAuthenticated: true,
                accessToken
            }
        });
    };

    const logout = async () => {
        setSession(null);
        dispatch({
            type: 'LOGOUT',
            payload: {
                isAuthenticated: false
            }
        });
    };

    const register = async (email, name, password) => {
        const response = await axios.post('/api/account/register', {
            email,
            name,
            password
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem('accessToken', accessToken);
        dispatch({
            type: 'REGISTER',
            payload: {
                user
            }
        });
    };

    const loginToken = async (accessToken) => {
        try {
            // if (accessToken && jwt.verify(accessToken, JWT_SECRET)) {
            console.log('In login toekn--', accessToken);
            const decodedToken = decodeToken(accessToken);

            if (decodedToken) {
                setSession(accessToken);
                const response = await axiosPrivate.get('/api/v1/user/dashboard');
                const { user } = response.data;

                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user
                    }
                });
            }
        } catch (err) {
            console.log('error catch login token');
            console.error(err);
            setSession(null);
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated: false,
                    user: null
                }
            });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                loginToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
