import React, { useContext } from 'react';

import '../../App.css';

// ... rest of imports
import '../../css/bootstrap.css';
import '../../style.css';
import '../../css/dark.css';
import '../../css/swiper.css';

import '../../assets/shop/shop.css';
import '../../assets/shop/css/fonts.css';

import '../../css/font-icons.css';
import '../../css/animate.css';
import '../../css/custom.css';


import MainNavigation from '../MainNavigation';
import TopBar from '../TopBar';

import { Link, useNavigate } from 'react-router-dom';
// import ShoppingCartContext from '../../Context/ShoppingCartContext';
import { AuthProvider } from '../../Context/JWTAuthContext';
import useAuth from '../../hooks/useAuth';
import TopCartIndex from '../TopCart/TopCartIndex';
import LogoutIcon from '@mui/icons-material/Logout';
import { Tooltip } from '@mui/material';
import MobileMenu from './MobileHeader';
import { axiosPrivate } from '../../api/privateAxios';

const HeaderContainer = () => {
    let navigate = useNavigate();
    // let { cartQuantity } = useContext(ShoppingCartContext);
    // let { isAuthenticated } = useAuth();
    let accessToken = localStorage.getItem('accessToken');
    // console.log("isAuthenticated in header=>", isAuthenticated);

    const signout = async () => {
        localStorage.removeItem('accessToken');

        let userLogoutResponse = await axiosPrivate.post('/api/v1/user/user-logout');
        console.log('user Logout Response: ', userLogoutResponse);
        navigate('/');
    }
    return (
        <>
            <div id="top-bar" className="dark" style={{ "backgroundColor": "#a3a5a7" }}>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-auto">
                            <p className="mb-0 d-flex justify-content-center justify-content-lg-start py-3 py-lg-0">
                                <strong> Free Shipping on Order above $49</strong>
                            </p>
                        </div>

                        <div className="col-12 col-lg-auto d-none d-lg-flex">

                            <TopBar />
                            <ul id="top-social">
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100071660112200" rel="noreferrer" target="_blank"
                                        className="si-facebook"><span className="ts-icon"><i className="icon-facebook"></i></span><span
                                            className="ts-text">Facebook</span></a>
                                </li>
                                <li><a href="https://www.instagram.com/ayu.sage/" target="_blank" rel="noreferrer" className="si-instagram"><span
                                    className="ts-icon"><i className="icon-instagram2"></i></span><span
                                        className="ts-text">Instagram</span></a></li>
                                <li><a href="tel:4168907202" className="si-call"><span className="ts-icon"><i
                                    className="icon-call"></i></span><span className="ts-text">+1 (437) 937-2020</span></a>
                                </li>
                                <li><a href="mailto:info@ayusage.com" className="si-email3"><span className="ts-icon"><i
                                    className="icon-envelope-alt"></i></span><span
                                        className="ts-text">info@ayusage.com</span></a></li>
                            </ul>
                            {/* <!-- #top-social end --> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Header --> */}
            <header id="header" className="full-header header-size-md">
                <div id="header-wrap">
                    <div className="container">
                        <div className="header-row justify-content-lg-between">
                            {/* <!-- Logo --> */}
                            <div id="logo" className="me-lg-4">
                                <a href="/" className="standard-logo">
                                    <img src="https://ayusage-public.s3.us-east-2.amazonaws.com/ayusage-logo.png" alt="Ayusage" />
                                </a>
                                <a href="/" className="retina-logo">
                                    <img src="https://ayusage-public.s3.us-east-2.amazonaws.com/ayusage-logo.png" alt="Ayusage" />
                                </a>
                            </div>

                            <div className="header-misc">

                                <div id="top-account">
                                    {!accessToken ? (<Link to="/login">
                                        {/* <i className="icon-line2-user me-1 position-relative" style={{ "top": "1px" }}></i> */}
                                        {/* <span className="d-none d-sm-inline-block font-primary fw-medium">Login</span> */}
                                    </Link>) :
                                        <>
                                            <Link to="/user-account/dashboard">
                                                <i className="icon-line2-user me-1 position-relative" style={{ "top": "1px" }}></i>
                                                <span className="d-none d-sm-inline-block font-primary fw-medium">My Account</span>
                                            </Link> &nbsp;
                                            <Link className='lgot-lnk'>
                                                <Tooltip title='Logout'>
                                                    <LogoutIcon onClick={signout} />
                                                </Tooltip>

                                            </Link>
                                        </>
                                    }
                                </div>

                                {/* <!-- Top Cart */}
                                <TopCartIndex></TopCartIndex>
                                {/* <!-- Top Search */}
                                <div id="top-search" className="header-misc-ico d-none">
                                    <Link to={"/"} id="top-search-trigger">
                                        {/* <i className="icon-line-search"></i>
                                        <i className="icon-line-cross"></i> */}
                                    </Link>
                                </div>
                            </div>
                            <MobileMenu></MobileMenu>

                            {/* <div id="primary-menu-trigger">
                                <svg className="svg-trigger" viewBox="0 0 100 100">
                                    <path
                                        d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20">
                                    </path>
                                    <path d="m 30,50 h 40"></path>
                                    <path
                                        d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20">
                                    </path>
                                </svg>
                            </div> */}

                            <MainNavigation />

                        </div>
                    </div>
                </div>
                <div className="header-wrap-clone"></div>
            </header>

        </>
    )
}

export default HeaderContainer;