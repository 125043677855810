import { Box, FormControl, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../../api/privateAxios';

// import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SideBarComponent from '../SideBar/SideBarComponent';
const DashboardContainer = () => {
    let navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('email@gmail.com');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');

    const getDashboardData = useCallback(async () => {
        try {
            console.log('axiosPrivate: ', axiosPrivate);
            let dashbordReponse = await axiosPrivate.post('/api/v1/user/dashboard');
            console.log('dashbord-Reponse: ', dashbordReponse);
            if (dashbordReponse?.data?.status === 404) {
                localStorage.clear('accessToken');
                navigate('/');
            } else {
                console.log('dashbordReponse?.data?.data?.user?.email:', dashbordReponse?.data?.data?.user?.email);
                setUserEmail(dashbordReponse?.data?.data?.user?.email);
                setUserFirstName(dashbordReponse?.data?.data?.user?.firstname);
                setUserLastName(dashbordReponse?.data?.data?.user?.lastname);
            }
        } catch (error) {
            console.log(error);
        }
    }, [])
    useEffect(() => {
        getDashboardData()
    }, [getDashboardData]);

    return (
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div id="page-title" style={{ backgroundColor: "transparent" }} className="mb- 5 page-title-mini">
                        <h3>Hi Sandeep Sidhu</h3>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </ol>
                    </div>
                    <div className="row clearfix shadow main-accunt">
                        <SideBarComponent></SideBarComponent>
                        <div className="col-md-9 px-5 py-4">
                            <Box component="form" sx={{ '& .MuiTextField-root': { mb: 2, mx: 1 } }} autoComplete="off">
                                <h3>Account Information</h3>
                                <div className="">
                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="First Name"
                                            name="full_name"
                                            defaultValue={userFirstName || ''}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="Last Name"
                                            name="full_name"
                                            defaultValue={userLastName || ''}
                                        />
                                    </FormControl>

                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id="outlined-error"
                                            label="Email"
                                            name="email"
                                            defaultValue={userEmail || ''}
                                        //onChange={handlingEmail}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id=""
                                            label="Phone Number"
                                            name="phone_number"
                                            defaultValue={''}
                                        //onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-6'>
                                        {/* <InputLabel id="">Full Name</InputLabel> */}
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="Company"
                                            name="full_name"
                                            defaultValue={''}
                                        //onChange={handlingFullName}
                                        />
                                    </FormControl>

                                    <div className='col-md-12 mx-2'>
                                        <button className="button bg-dark text-white button-dark button-large m-0" >Save</button>
                                    </div>
                                </div>
                            </Box>
                            <div className="clear"></div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default DashboardContainer