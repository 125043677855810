import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "../../api/axios";
import FaqAccordion from "./FaqAccordion";

const FaqsPage = () => {

    const [faq, setFaq] = useState(null);

    const getFaq = useCallback(async () => {
        const response = await axios.get('/api/v1/faq');
        setFaq(response?.data?.data);
    }, []);

    //console.log(faq);

    useEffect(() => {
        getFaq();
    }, [getFaq]);

    return <>
        <Helmet>
            <title>FAQs | Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Checkout frequently asked questions and contact us for more queries!" />
        </Helmet>
        <section id="page-title" className='' style={{ "background": "url('./faq.jpg')" }}>
            <div className="container">
                <h1 style={{ "color": "#FFFFFF" }}>FAQs</h1>
            </div>
        </section>


        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="sections row">

                        <div className="col-lg-12">

                            <p>The minds behind our products are the expert who are well acquainted with the knowledge and wisdom of ayurveda. our products are specially formulated on the foundation of disease and address the root cause of your issue not merely the symptoms.
                            </p>
                            <p>Every tablet and capsule itself is the gift of nature in your hands. We provide the best ayurvedic supplements across the world that are 100% authentic product from the land of veda. Our products are based on the principle of ayurveda which works only roots of problem so to have permanet and ever lasting benefits.
                            </p>
                        </div>

                    </div>



                    {/* <!-- FAQs
          ============================================= --> */}
                    <div className="sections">
                        <div className="row">
                            <div className="col-lg-12">
                                {faq && faq.map((item) => {
                                    return <FaqAccordion title={item.title} content={item.content}></FaqAccordion>
                                })

                                }


                            </div>

                            <div className="clear"></div>

                        </div>
                    </div>
                    {/* <!-- Div end
          ============================================= --> */}

                </div>
            </div>
        </section>

    </>
}
export default FaqsPage;