import React, { useState } from 'react';
import { Offcanvas, OffcanvasBody, Stack } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../../css/custom.css';

import MainNavigation from '../MainNavigation';

function MobileMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        console.log(isMenuOpen);
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
                {/* Your mobile menu content goes here */}
            </div>
            <div className={`offcanvas-container ${isMenuOpen ? 'show' : ''}`}>

                <Offcanvas className="mb-top" show={isMenuOpen} onHide={toggleMenu} placement="end">
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <OffcanvasBody>
                        <Stack>
                            <header className='' >
                                <nav className="mobile-menus with-arrows me-lg-auto">

                                    <ul className="menu-container-x">
                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/">Home</a></div>
                                        </li>
                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/about">About</a></div>
                                        </li>
                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/shop">Shop</a></div>
                                        </li>

                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/contact">Contact</a></div>
                                        </li>
                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/blog">Blog</a></div>
                                        </li>
                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/bodytype">Know Your Body Type</a></div>
                                        </li>
                                        <li className="menu-item">
                                            <div className="menu-link"><a href="/consultation">Book Free Consultation</a></div>
                                        </li>
                                    </ul>

                                </nav>

                            </header>
                        </Stack>
                    </OffcanvasBody>
                </Offcanvas>

            </div>

            <div onClick={toggleMenu} id="primary-menu-trigger">
                <svg className="svg-trigger" viewBox="0 0 100 100">
                    <path
                        d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20">
                    </path>
                    <path d="m 30,50 h 40"></path>
                    <path
                        d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20">
                    </path>
                </svg>
            </div>


        </>
    );
}

export default MobileMenu;
