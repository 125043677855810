import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsentBanner.css'; // Import CSS file for styling

const CookieConsentBanner = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);

  const handleAccept = () => {
    setCookie('cookieConsent', true, { path: '/' });
    setShowBanner(false);
  };

  const handleReject = () => {
    setCookie('cookieConsent', false, { path: '/' });
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <div className="banner-content">

        <p style={{ fontSize: '14px' }}>
          Ayusage use cookies to ensure you the most relevant experience by remembering your preferences on this website. </p><br />
        <button className='accpt-cok-btn' onClick={handleAccept}>Accept Cookies</button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
