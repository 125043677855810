// CartContext.js
import React, { createContext, useContext, useReducer } from "react";

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

const initialState = {
    cartItems: [],
    shippingPrice: 9.0,
    totalHST: 13.0,
    isShippingFree: false,
    couponInvalidMessage: "",
    couponData: {},
    couponCode: "",
    finalSubtotal: 0,
    finalTotal: 0, // Add a field for final total
};

function cartReducer(state, action) {
    switch (action.type) {
        case "SET_CART_ITEMS":
            return { ...state, cartItems: action.payload };
        case "SET_SHIPPING_PRICE":
            return { ...state, shippingPrice: action.payload };
        case "SET_TOTAL_HST":
            return { ...state, totalHST: action.payload };
        case "SET_IS_SHIPPING_FREE":
            return { ...state, isShippingFree: action.payload };
        case "SET_COUPON_INVALID_MESSAGE":
            return { ...state, couponInvalidMessage: action.payload };
        case "SET_COUPON_DATA":
            return { ...state, couponData: action.payload };
        case "SET_COUPON_CODE":
            return { ...state, couponCode: action.payload };
        case "SET_FINAL_SUBTOTAL":
            return { ...state, finalSubtotal: action.payload };
        case "SET_FINAL_TOTAL":
            return { ...state, finalTotal: action.payload };
        default:
            return state;
    }
}

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    // Function to calculate the subtotal
    const calculateSubtotal = (cartItems, productList) => {
        return cartItems.reduce((total, cartItem) => {
            const item = productList?.find(i => i.id === cartItem.id);
            return total + (item?.sale_price || 0) * cartItem?.quantity;
        }, 0);
    };

    // Function to calculate the total
    const calculateTotal = () => {
        // console.log("Calculating Total...", state.finalSubtotal);
        const shippingCost = state.isShippingFree ? 0 : state.shippingPrice;
        // console.log("Shipping Cost:", shippingCost);
        const hst = (parseFloat(state.finalSubtotal) * parseFloat(state.totalHST)) / 100;
        // console.log("HST:", hst);
        const cartSubtotal = parseFloat(state.finalSubtotal);
        // console.log("Cart Subtotal:", cartSubtotal);
        const finalTotal = (cartSubtotal + hst + parseFloat(shippingCost)).toFixed(2);
        // console.log("Final Total:", finalTotal);
        dispatch({ type: "SET_FINAL_TOTAL", payload: finalTotal });
    };

    return (
        <CartContext.Provider value={{ state, dispatch, calculateSubtotal, calculateTotal }}>
            {children}
        </CartContext.Provider>
    );
};
