// import { useContext, useDebugValue } from "react";
// import { AuthContext } from "../Context/AuthProvider";

// const useAuth = () => {
//     const { auth } = useContext(AuthContext);
//     console.log('auth in useauth hook: ', auth);
//     useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out")
//     return useContext(AuthContext);
// }

// export default useAuth;

import { useContext } from 'react';
import AuthContext from '../Context/JWTAuthContext';

const useAuth = () => useContext(AuthContext);

export default useAuth;
