import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ShippingDeliveryPolicy = () => {
    return <>
        <Helmet>
            <title>Shipping - Delivery | Ayusage Private Limited - Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Explore our Privacy Policy to understand how we collect, use, and safeguard your personal information." />
        </Helmet>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Shipping and Delivery Policy:</h3>

                            <p>At Ayusage, we prioritize seamless delivery to ensure your satisfaction. Here's what you need to know:</p>

                            <h4>Shipping Methods:</h4>

                            <p>We utilize trusted carriers such as CanadaPost, UPS, and Purolator for standard shipping within Canada. For international orders, we partner with registered international courier companies and/or International speed post for secure delivery.</p>


                            <h4>Free Shipping:</h4>

                            <p>Enjoy complimentary standard shipping within Canada on orders surpassing $49.00 CAD after discounts and excluding taxes.</p>

                            <h4>Shipping Fee:</h4>
                            <p>Orders below $49.00 CAD post-discounts and taxes will incur a flat shipping fee of $8.99 CAD, non-refundable yet ensuring your products reach you swiftly and securely.</p>


                            <h4>Delivery Timeline:</h4>

                            <p>We endeavor to dispatch orders within 5-14 days or as per the agreed delivery date specified during order confirmation. While we commit to timely handover to our shipping partners, please note that delivery timelines may vary based on courier company or postal authorities' norms.</p>

                            <h4>Address Confirmation:</h4>

                            <p>To ensure accurate delivery, kindly provide the precise address during checkout. We will dispatch your order to the address specified by you.</p>

                            <h4>Order Confirmation:</h4>

                            <p>Upon dispatch, you'll receive confirmation on your registered email ID. This ensures transparency and keeps you informed about your order status.</p>

                            <h4>Customer Support:</h4>

                            <p>For any queries or assistance, our dedicated helpdesk is available at +91-9780338282 or via email at ayusage5@gmail.com. Your satisfaction is our priority, and we're here to assist you every step of the way.</p>

                            <p>Should you have any concerns regarding your shipment's status, please refer to our comprehensive <a href="/faq">FAQ page</a>  or reach out to us directly at ayusage5@gmail.com.</p>

                            <p>At Ayusage, your wellness journey is our commitment, and timely, reliable delivery is a cornerstone of our service.</p>




                            <h3>Need help?</h3>
                            <p>Contact us at <Link to="mailto:ayusage5@gmail.com">ayusage5@gmail.com</Link> for questions related to refunds and returns.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    </>
}
export default ShippingDeliveryPolicy;