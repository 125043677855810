let products = [
    {
        "id": 1,
        "product_name": "Hairsage",
        "product_price": "15.49",
        "product_sale_price": "",
        "product_quantity": "",
        "product_stock": "",
        "product_category": "",
        "product_tags": "",
        "product_short_description": "",
        "product_long_description": "",
        "product_main_image": "",
        "product_thumnail_1": "",
        "product_thumnail_1": "",
        "product_is_active": "",
        "created_date": "",
        "modified_date": ""
    },
    {
        "id": 2,
        "product_name": "Mucuna",
        "product_price": "12.50",
        "product_sale_price": "",
        "product_quantity": "",
        "product_stock": "",
        "product_category": "",
        "product_tags": "",
        "product_short_description": "",
        "product_long_description": "",
        "product_main_image": "../images/Ayusage.png",
        "product_thumnail_1": "",
        "product_thumnail_1": "",
        "product_is_active": "",
        "created_date": "",
        "modified_date": ""
    },
    {
        "id": 3,
        "product_name": "Turmeric",
        "product_price": "14.50",
        "product_sale_price": "",
        "product_quantity": "",
        "product_stock": "",
        "product_category": "",
        "product_tags": "",
        "product_short_description": "",
        "product_long_description": "",
        "product_main_image": "",
        "product_thumnail_1": "",
        "product_thumnail_1": "",
        "product_is_active": "",
        "created_date": "",
        "modified_date": ""
    },
    {
        "id": 4,
        "product_name": "Moringa",
        "product_price": "12.99",
        "product_sale_price": "",
        "product_quantity": "",
        "product_stock": "",
        "product_category": "",
        "product_tags": "",
        "product_short_description": "",
        "product_long_description": "",
        "product_main_image": "",
        "product_thumnail_1": "",
        "product_thumnail_1": "",
        "product_is_active": "",
        "created_date": "",
        "modified_date": ""
    }
]

export default products;