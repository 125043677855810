import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../account.css'; // Custom CSS file for styling
import GridViewIcon from '@mui/icons-material/GridView';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
const SideBarComponent = () => {

    let navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear('accessToken');
        navigate('/');
    }
    return (
        <>
            <div className="col-md-3 p-0 account-sidebar">

                <div className="list-group">
                    <Link to={'/user-account/dashboard'} className="list-groups-item list-group-item-action d-flex justify-content-between">
                        <div>Dashboard</div><GridViewIcon />
                    </Link>
                    <Link to={'/user-account/address'} className="list-groups-item list-group-item-action d-flex justify-content-between">
                        <div>Address Book</div> <ImportContactsIcon />
                    </Link>
                    <Link to={'/user-account/orders'} className="list-groups-item list-group-item-action d-flex justify-content-between">
                        <div>My Orders</div><ShoppingBagIcon />
                    </Link>
                    <Link to={'/user-account/return'} className="list-groups-item list-group-item-action d-flex justify-content-between">
                        <div>Return</div>
                        <AssignmentReturnIcon />
                    </Link>
                    <Link to={'/user-account/change-password'} className="list-groups-item list-group-item-action d-flex justify-content-between">
                        <div>Change Password</div>
                        <KeyIcon />
                    </Link>
                    <Link onClick={handleLogout} className="list-groups-item list-group-item-action d-flex justify-content-between">
                        <div>Logout</div>
                        <LogoutIcon />
                    </Link>
                </div>
                <div className='bottom-sidebar'>
                    <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-conditions">Terms & Conditions</Link>
                </div>
            </div>
        </>
    )
}

export default SideBarComponent