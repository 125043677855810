import React from 'react'
import MainCartContainer from './MainCartContainer'

const MainCartIndex = (props) => {
    let page_type = props.type;
    let billingData = props.billingData;
    let shippingData = props.shippingData;
    return (
        <MainCartContainer billingData={billingData} shippingData={shippingData} type={page_type}></MainCartContainer>
    )
}

export default MainCartIndex