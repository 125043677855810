import { Box, FormControl, TextField } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import SideBarComponent from '../SideBar/SideBarComponent'

const Profile = () => {
    return (
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div id="page-title" style={{ backgroundColor: "transparent" }} className="mb- 5 page-title-mini">
                        <h3>Hi Sandeep Sidhu</h3>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/user-account/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Address Book</li>
                        </ol>
                    </div>
                    <div className="row clearfix shadow main-accunt">
                        <SideBarComponent></SideBarComponent>
                        <div className="col-md-9 px-5 py-4">
                            <Box component="form" sx={{ '& .MuiTextField-root': { mb: 2, mx: 1 } }} autoComplete="off">
                                <h3>Address Details</h3>
                                <div className="">
                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="First Name"
                                            name="full_name"
                                            defaultValue={''}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-6'>
                                        {/* <InputLabel id="">Full Name</InputLabel> */}
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="Last Name"
                                            name="full_name"
                                            defaultValue={''}
                                        //onChange={handlingFullName}
                                        />
                                    </FormControl>

                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id="outlined-error"
                                            label="Email"
                                            name="email"
                                            defaultValue={''}
                                        //onChange={handlingEmail}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-6'>
                                        <TextField
                                            id=""
                                            label="Phone Number"
                                            name="phone_number"
                                            defaultValue={''}
                                        //onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl className='col-md-6'>
                                        {/* <InputLabel id="">Full Name</InputLabel> */}
                                        <TextField
                                            id="outlined-basic outlined-error"
                                            label="Company"
                                            name="full_name"
                                            defaultValue={''}
                                        //onChange={handlingFullName}
                                        />
                                    </FormControl>

                                    <div className='col-md-12 mx-2'>
                                        <button className="button bg-dark text-white button-dark button-large m-0" >Save</button>
                                    </div>

                                </div>


                            </Box>

                            <div className="clear"></div>
                        </div>
                        <div className="w-100 line d-block d-md-none"></div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Profile