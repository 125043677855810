import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ForgotPasswordPage = () => {
    return (
        <>
            <section id="page-title" className="page-title-mini">
                <div className="container clearfix">
                    <h1>Forgot Password</h1>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Forgot Password</li>
                    </ol>
                </div>
            </section>
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-6 m-auto shadow p-5">
                                <h3>Forgot your Password? Reset Now!</h3>

                                <p>You will receive an email for password reset. Make sure you use the right email!</p>
                                <FormControl className='col-md-12'>
                                    {/* <InputLabel id="">Full Name</InputLabel> */}
                                    <TextField
                                        //error={isFullNameEmpty}
                                        id="outlined-basic outlined-error"
                                        label="Email"
                                        name="login-form-username"
                                        defaultValue={''}
                                    />
                                </FormControl>

                                <div className="col-12 mt-4">
                                    <button className="button bg-dark text-white button-dark button-medium m-0" id="register-form-submit" name="register-form-submit" value="register">Reset Password</button>
                                    <Link to="/login" className="float-end">Already have an account?</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPasswordPage