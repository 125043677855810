import React, { useState } from 'react';
import Question from './Question';
import ProgressBar from './ProgressBar';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';


const QuizComponent = (props) => {
    let questions = props?.questions;
    let email = props?.enteredEmail;
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState(Array(questions?.length).fill(null));
    const [showValidation, setShowValidation] = useState(false);
    let navigate = useNavigate();

    const handleNext = (selectedOption) => {
        if (selectedOption !== null) {
            const newAnswers = [...answers];
            newAnswers[currentQuestion] = selectedOption;
            setAnswers(newAnswers);
            setShowValidation(false);
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowValidation(true);
        }
    };
    const handlePrevious = () => {
        setCurrentQuestion(currentQuestion - 1);
    };

    const handleSubmit = (async () => {
        if (answers.every((answer) => answer !== null)) {
            // Prepare data in array of objects format
            const dataToSend = questions?.map((question, index) => ({
                questionId: question.id,
                answer: answers[index]
            }));
            let new_data = []

            new_data.push({ 'email': email, dataToSend });
            let bodyTypeText;
            // Extract answers from data
            const get_answers = dataToSend.map((item) => item.answer);

            // Count occurrences of each answer
            const answerCounts = get_answers.reduce((acc, ans) => {
                acc[ans] = (acc[ans] || 0) + 1;
                return acc;
            }, {});

            // Convert answerCounts to an array of objects
            const answerCountsArray = Object.entries(answerCounts).map(([answer, count]) => ({
                answer,
                count,
            }));
            console.log('Answer Counts Array: ', answerCountsArray);
            // Sort answerCountsArray by count in descending order
            answerCountsArray.sort((a, b) => b.count - a.count);

            // Extract most, second most, and third most common answers
            const mostCommon = answerCountsArray[0];
            const secondMostCommon = answerCountsArray[0];
            // const thirdMostCommon = answerCountsArray[2];
            // return { mostCommon, secondMostCommon, thirdMostCommon };
            console.log('Most Common: ', mostCommon, secondMostCommon);
            const dominantType = mostCommon?.answer || '';
            const secondDominantType = secondMostCommon?.answer || '';
            if ((dominantType === 'A' && secondDominantType === 'A')) {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Kapha type.`;
            }
            else if ((dominantType === 'B' && secondDominantType === 'B')) {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Pitta type.`;
            }
            else if ((dominantType === 'C' && secondDominantType === 'C')) {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Vatta type.`;
            }
            else if ((dominantType === 'A' && secondDominantType === 'B')) {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Kapha dominant Pitta type.`;
            }
            else if (dominantType === 'B' && secondDominantType === 'A') {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Pitta dominant Kapha type.`;
            }
            else if ((dominantType === 'A' && secondDominantType === 'C')) {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Kapha dominant Vata type.`;
            }
            else if (dominantType === 'C' && secondDominantType === 'A') {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Vata dominant Kapha type.`;
            }
            else if ((dominantType === 'B' && secondDominantType === 'C')) {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Pitta dominant Vata type.`;
            }
            else if (dominantType === 'C' && secondDominantType === 'B') {
                bodyTypeText = `Your Ayurveda Body Type (Prakruti) is Vata dominant Pitta type.`;
            }
            let body_dosha_type;
            if (dominantType === 'A') {
                body_dosha_type = 'kapha';
            } else if (dominantType === 'B') {
                body_dosha_type = 'pita';
            } else if (dominantType === 'C') {
                body_dosha_type = 'vata';
            }
            let data = {
                body_type_text: bodyTypeText,
                body_type_description: 'Know more about your body, mind and holistic ways to balance your doshas  for healthy body and mind.',
                body_type: body_dosha_type,
                email_to: email,
            }
            const response = await axios.post('/api/v1/body_type_request', data);
            // console.log('Response Send Email :=> ', response);

            // navigate('/submit-know-your-body-type');
            navigate('/submit-know-your-body-type', {
                state: { bodyTypeText }, // or query: { new_data }
            });
        } else {
            setShowValidation(true);
        }
    });

    return (
        <div className='main-type shadow mb-5'>
            <ProgressBar
                style={{
                    width: '90% !important',
                    maxWidth: '100% !important',
                    margin: '0 auto !important',
                    borderRadius: '10px !important',
                    background: '#f0f0f0 !important',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1) !important',
                    position: 'relative !important', // Add position for overflow control
                }}
                current={currentQuestion + 1}
                total={questions?.length}
            >
                <div
                    style={{
                        color: '#000 !important',
                        position: 'absolute !important', // Position the percentage within the progress bar
                        top: '50% !important', // Vertically center the percentage
                        left: '50% !important', // Horizontally center the percentage
                        transform: 'translate(-50%, -50%) !important', // Adjust for centering
                    }}
                >
                    {Math.round(((currentQuestion + 1) / questions?.length) * 100)}%
                </div>
            </ProgressBar>
            {currentQuestion < questions?.length ? (
                <Question
                    question={questions?.[currentQuestion]}
                    onNext={handleNext}
                    onPrevious={currentQuestion > 0 ? handlePrevious : undefined}
                    showValidation={showValidation}
                    selectedAnswer={answers[currentQuestion]}
                />
            ) : (
                <section id="content">
                    <div className="content-wrap">
                        <div className="container clearfix">
                            <div className="sections row">
                                <div className="col-sm-12 col-lg-12"></div>
                                <div className="text-center py-5">
                                    <h2>Great! You are at final step.</h2>
                                    <h4>Hit the submit button to get the final result.</h4>
                                    <button className='button bg-dark text-white button-dark button-medium ms-0' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            )}
        </div>
    );

};
export default QuizComponent;
