import React, { useContext } from 'react';
import ShoppingCartContext from '../../Context/ShoppingCartContext';
import { ProductContext } from '../../Context/ProductContext';

// import { formatCurrency } from "../utilities/formatCurrency"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { Link } from "react-router-dom";
import './Index.css';

const MainCartItem = ({ id, quantity }) => {
    const { removeFromCart, increaseCartQuantity, decreaseCartQuantity } = useContext(ShoppingCartContext);
    const { productList } = useContext(ProductContext)
    // console.log('productList in cart item: ', productList, 'quantity: ', quantity);

    const item = productList.find(i => i.id == id)
    if (item == null) return null

    // console.log('item: ', item);
    const handleIncreaseProductQuantity = async (product_id) => {
        await increaseCartQuantity(product_id);
    }
    const handleDecreaseProductQuantity = async (product_id, quantity) => {
        if (quantity > 1) {
            await decreaseCartQuantity(product_id);
        }
    }
    return (
        <tr className="cart_item">
            <td className="cart-product-remove">
                <Tooltip className='up-down-arrw' title={"Remove " + item.name}>
                    <DeleteIcon className='dlt-icn' onClick={() => removeFromCart(item.id)} />
                </Tooltip>
            </td>
            <td className="cart-product-thumbnail">
                <span><img alt='ima' src={`https://ayusage-public.s3.us-east-2.amazonaws.com/product/${item.slug}/${item?.thumbnail_1}`} style={{ width: "125px", height: "100px", objectFit: "cover" }} /></span>
            </td>

            <td className="cart-product-name"> <Link to={'/product/' + item.slug} alt={item.name}>{item.name}</Link> </td>

            <td className="cart-product-price">
                <span className="amount">${item.sale_price}</span>
            </td>
            <td className="cart-product-quantity">
                <div className="">
                    <Tooltip className={quantity === 1 ? 'dwn-btn-disable' : 'up-down-arrw'} title={"Decrease quantity "}>
                        <ArrowDownwardTwoToneIcon onClick={() => handleDecreaseProductQuantity(item?.id, quantity)} />
                    </Tooltip>
                    <span className='qntty-cnt'>{quantity} </span>
                    <Tooltip className='up-down-arrw' title={"Increase quantity"}>
                        <ArrowUpwardIcon onClick={() => handleIncreaseProductQuantity(item?.id)} />
                    </Tooltip>

                </div>
            </td>
            <td className="cart-product-subtotal">
                <span className="amount">${(item.sale_price * quantity).toFixed(2)}</span>
            </td>
        </tr>
    )
}

export default MainCartItem;