import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { ProductProvider } from '../../Context/ProductContext'
import { ShoppingCartProvider } from '../../Context/ShoppingCartContext'
import HomePageContainer from './HomePageContainer'

const HomeContainerIndex = () => {
    return (
        <ProductProvider>
            <Helmet>
                <title>Ayusage - Natural Ayurvedic Supplement Store - Land of Vedas</title>
                <meta name="description" content="Ayusage - Best and 100% Natural Ayurvedic supplement Store that delivers straight from the land of Vedas. Ayusage products are based on the principles of 5000 years old science of Ayurveda which works on the root cause of the problem so as to have permanent and ever-lasting health benefits." />
            </Helmet>
            <HomePageContainer></HomePageContainer>
        </ProductProvider>

    )
}
export default HomeContainerIndex;
