import { Helmet } from 'react-helmet';
import BestSellingIndex from '../../component/BestSelling/BestSellingIndex';

const ShopPage = () => {

    return <>
        <Helmet>
            <title>Shop Ayusage Supplements - 100% Natural Ayurvedic Supplement Store</title>
            <meta name="description" content="Shop Ayusage - Best and 100% Natural Ayurvedic supplement Store that delivers straight from the land of Vedas. Ayusage products are based on the principles of 5000 years old science of Ayurveda which works on the root cause of the problem so as to have permanent and ever-lasting health benefits." />
        </Helmet>
        {/* <!-- Page Title-> */}
        <section id="page-title" className='' style={{
            "background": "url('./shop-ayurvedic-products.jpeg')",
        }}>
            <div className="container">
                <h1> Shop</h1>
            </div >
        </section >
        <div className="my-5">
            <div className="container">
                <div className="row align-items-stretch">

                    <BestSellingIndex type={'all'} />
                </div>
            </div>
        </div>
    </>
}
export default ShopPage;